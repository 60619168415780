import firebase from "firebase";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCUBtbFW85-w_5oISXMcy80CA5J9gDlKX8",
  authDomain: "pictor11-b3560.firebaseapp.com",
  databaseURL: "https://pictor11-b3560-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "pictor11-b3560",
  storageBucket: "pictor11-b3560.appspot.com",
  messagingSenderId: "801474451864",
  appId: "1:801474451864:web:d0a36569f9db1d8a28b3ed",
  measurementId: "G-PQ62P55R2G"
  };

var fireDb 
if (!firebase.apps.length) {

    fireDb = firebase.initializeApp(firebaseConfig);
  } else {
    fireDb = firebase.app();
  }
  export default fireDb;