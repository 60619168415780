import React, { Component, useEffect, useState, useRef } from 'react'; 
import styled from "styled-components"
import Firebase from "firebase";
import firebase from "../firebase";
import fireDb from "../firebase";
import moment from 'moment'

import Select from 'react-select'
import imageCompression from "browser-image-compression";

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
        outerWidth : "600px",
        innerWidth : "600px",
        
        
      };
    }
  };
  
  
  const categoryOptions1 = [
    
    { value: "디지털그래픽 CGI(2D/3D)	computer-generated-imagery-2d-3d", label: "디지털그래픽 CGI(2D/3D)	computer-generated-imagery-2d-3d", color : "#AE47FF", },
    { value: "웹툰/만화 Webtoon/Comics	webtoon-comics", label: "웹툰/만화 Webtoon/Comics	 ", color : "#AE47FF", },
    { value: "일러스트 Illustration	illustration", label: "일러스트 Illustration	 ", color : "#AE47FF", },
    { value: "캐릭터디자인 Character Design	character-design", label: "캐릭터디자인 Character Design	 ", color : "#AE47FF", },
    { value: "캘리그래피 Calligraphy	calligraphy", label: "캘리그래피 Calligraphy	 ", color : "#AE47FF", },
    { value: "타투디자인 Tatoo Design	tatoo-design", label: "타투디자인 Tatoo Design	 ", color : "#AE47FF", },
    { value: "패션일러스트 Fashion Illustration	fashion-illustration", label: "패션일러스트 Fashion Illustration ", color : "#AE47FF", },
    { value: "패턴디자인 Pattern Design	pattern-design", label: "패턴디자인 Pattern Design ", color : "#AE47FF", },
    { value: "피규어디자인 Art Toy	art-toy", label: "피규어디자인 Art Toy ", color : "#AE47FF", },
    
    
    ];
  
    const categoryOptions2 = [
  
        { value : "사진 Photography	photography", label :  "사진 Photography ", color : "#AE47FF", },
        { value : "조각 Sculpture	sculpture", label :  "조각 Sculpture ", color : "#AE47FF", },
        { value : "판화 Print	print", label :  "판화 Print ", color : "#AE47FF", },
        { value : "회화 Painting	painting", label :  "회화 Painting ", color : "#AE47FF", },
      
      
      ];
  
      
  
      const categoryOptions3 = [
        { value: "모션그래픽 Motion Graphic	motiongraphic", label: "모션그래픽 Motion Graphic", color : "#AE47FF",},
        { value: "미디어아트 Media Art	media-art", label: "미디어아트 Media Art ", color : "#AE47FF",},
        { value: "애니메이션 Animation	animation", label: "애니메이션 Animation ", color : "#AE47FF",},
        { value: "영화 Film Production	Film Production", label: "영화 Film Production ", color : "#AE47FF",},
        
        ]; 
        
  const flavourOptions = [
    { value: "vanilla", label: "Vanilla", rating: "safe" },
    { value: "chocolate", label: "Chocolate", rating: "good" },
    { value: "strawberry", label: "Strawberry", rating: "wild" }
    
  ];
  
  
  
  const groupedOptions = [
    {
      label: "그래픽아트 Graphic Art	Graphic Art",
      options: categoryOptions1
    }, 
    {
      label: "순수미술 Fine Art	fine-art",
      options: categoryOptions2
    }, 
    {
      label: "영상 Video Graphic	videoart",
      options: categoryOptions3
    },  
  ];
 
const color1 = `#8A2BE2`;
const color2 = `#9B1CFF`;
const color3 = `#2DFF0B`;
const compString1 = `popup2_`
const staticString1 = `/static/logo_pictor000.png`
const staticString2 = `/static/icon_sort.png`
const staticString3 = `/static/icon_search.png`
const staticString4 = `/static/icon_wow.png`
const staticString5 = '/static/left-arrow001.png';
const arr1 = ['Account', 'Name', 'beginning', 'habitat', 'Theme?', 'notes', 'Media', 'Artwork']

function Comp(props) {

    const [selectedUser, setSelectedUser] = useState()
    const [intUploadedNumber1, setIntUploadedNumber1] = useState(0);
    const [intStep0, setIntStep0] = useState(8);
    const [intStep1, setIntStep1] = useState(1);
    const [text1, setText1] = useState("");
    const [text2, setText2] = useState("");
    const [text3, setText3] = useState("");
    const [text4, setText4] = useState("");
    const [text5, setText5] = useState("");
    const [text6, setText6] = useState("");
    const [text7, setText7] = useState("");
    const [text8, setText8] = useState("");
    const [text9, setText9] = useState("human");

    const [selectedOption, setSelectedOption] = useState();


    const [textUploadId1, setTextUploadId1] = useState(moment().format("YYYYMMDDHHmmssms"))

    const textAreaRef1 = useRef(null);
    const textAreaRef2 = useRef(null);
    const imageRef0 = useRef();
    const imageRef1 = useRef();
    const imageRef2 = useRef();
    const imageRef3 = useRef();
    const imageRef4 = useRef();
    const imageRef5 = useRef();
    const imageRef6 = useRef();
    const imageRef7 = useRef();
    const imageRef8 = useRef();
    const imageRef9 = useRef();
    const imageRef10 = useRef();
  
    const [imageUpload0, setImageUpload0] = useState('');
    const [imageUpload1, setImageUpload1] = useState('');
    const [imageUpload2, setImageUpload2] = useState('');
    const [imageUpload3, setImageUpload3] = useState('');
    const [imageUpload4, setImageUpload4] = useState('');
    const [imageUpload5, setImageUpload5] = useState('');
    const [imageUpload6, setImageUpload6] = useState('');
    const [imageUpload7, setImageUpload7] = useState('');
    const [imageUpload8, setImageUpload8] = useState('');
    const [imageUpload9, setImageUpload9] = useState('');
    const [imageUpload10, setImageUpload10] = useState('');
    
    const [imageLink0, setImageLink0] = useState('');
    const [imageLink1, setImageLink1] = useState('');
    const [imageLink2, setImageLink2] = useState('');
    const [imageLink3, setImageLink3] = useState('');
    const [imageLink4, setImageLink4] = useState('');
    const [imageLink5, setImageLink5] = useState('');
    const [imageLink6, setImageLink6] = useState('');
    const [imageLink7, setImageLink7] = useState('');
    const [imageLink8, setImageLink8] = useState('');
    const [imageLink9, setImageLink9] = useState('');
    const [imageLink10, setImageLink10] = useState('');

    useEffect(()=> { 
        
        var username1 = "";
        const authStoreUserName = localStorage.getItem("authStoreUserName");
        const todoRef = firebase.database()
        .ref("accounts")
        .orderByChild("username")
        .equalTo(authStoreUserName);

        todoRef.once("value", (snapshot) => {
          const todos = snapshot.val(); 
          const todoList = [];
          for (let id in todos) {
            // console.log("id", id)
            todoList.push({ id, name : id,  ...todos[id] });
            setSelectedUser(todoList[0])
          }  
          // console.log("todoList->", todoList);
        });
 
      },[ ])
      useEffect(()=> { 
        if (selectedUser) {

            setText1(selectedUser.username)
            // setText2(selectedUser.password)
            // setText3(selectedUser.username)
            setText4(selectedUser.firstname)
            setText5(selectedUser.lastname)
            setText6(selectedUser.lastname)
            setText9(selectedUser.theme)
            setText7(selectedUser.nation)
            setText8(selectedUser.memo)

 
        }
      },[selectedUser])


    const actionImgCompress = async (paramIndex, fileSrc, type) => {
        console.log("압축 시작");
    
        const options = {
          maxSizeMB: 0.10,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        };
        try {
          // 압축 결과
          const compressedFile = await imageCompression(fileSrc, options);
          upload(paramIndex, compressedFile)
    
        } catch (error) {
          console.log(error);
        }
      };
    useEffect(()=> {
        const index1 = 0;
        actionImgCompress(0, imageUpload0);
        // checkImageNumber();
      },[
        imageUpload0,
      ])
    
      useEffect(()=> {
        const index1 = 1;
        actionImgCompress(index1, imageUpload1);
        checkImageNumber();
      },[
        imageUpload1,
      ])
      useEffect(()=> {
        const index1 = 2;
        actionImgCompress(index1, imageUpload2);
        checkImageNumber();
      },[
        imageUpload2,
      ])
      useEffect(()=> {
        const index1 = 3;
        actionImgCompress(index1, imageUpload3);
        checkImageNumber();
      },[
        imageUpload3,
      ])
    
      useEffect(()=> {
        const index1 = 4;
        actionImgCompress(index1, imageUpload4);
        checkImageNumber();
      },[
        imageUpload4,
      ])
      useEffect(()=> {
        const index1 = 5;
        actionImgCompress(index1, imageUpload5);
        checkImageNumber();
      },[
        imageUpload5,
      ])
      useEffect(()=> {
        const index1 = 6;
        actionImgCompress(index1, imageUpload6);
        checkImageNumber();
      },[
        imageUpload6,
      ])
      useEffect(()=> {
        const index1 = 7;
        actionImgCompress(index1, imageUpload7);
        checkImageNumber();
      },[
        imageUpload7,
      ])
    
      useEffect(()=> {
        const index1 = 8;
        actionImgCompress(index1, imageUpload8);
        checkImageNumber();
      },[
        imageUpload8,
      ])
      useEffect(()=> {
        const index1 = 9;
        actionImgCompress(index1, imageUpload9);
        checkImageNumber();
      },[
        imageUpload9,
      ])
      useEffect(()=> {
        const index1 = 10;
        actionImgCompress(index1, imageUpload10);
        checkImageNumber();
      },[imageUpload10,])
    
      function checkImageNumber() {
        var checkIndex1 = 0;
        if (imageUpload1) {
          checkIndex1 ++;
        }
        if (imageUpload2) {
          checkIndex1 ++;
        }
        if (imageUpload3) {
          checkIndex1 ++;
        }
        if (imageUpload4) {
          checkIndex1 ++;
        }
        if (imageUpload5) {
          checkIndex1 ++;
        }
        if (imageUpload6) {
          checkIndex1 ++;
        }
        if (imageUpload7) {
          checkIndex1 ++;
        }
        if (imageUpload8) {
          checkIndex1 ++;
        }
        if (imageUpload9) {
          checkIndex1 ++;
        }
        if (imageUpload10) {
          checkIndex1 ++;
        }
        setIntUploadedNumber1(checkIndex1)
    
      }

    function handleChange(selectedOption) {
      console.log("selectedOption", selectedOption);
      setSelectedOption(selectedOption) ;
  };

   

const upload = (paramIndex, paramUpload)=>{


  var returnName1 = `${paramUpload.name}_${moment().format("YYYYMMDD_HHmmss")}`;
  if(paramUpload == null)
    return;
    const uploadTask =  fireDb.storage().ref(`/images/${returnName1}`).put(paramUpload)
    uploadTask
    .on('state_changed', 
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      // switch (snapshot.state) {
      //   case 'paused':
      //     console.log('Upload is paused');
      //     break;
      //   case 'running':
      //     console.log('Upload is running');
      //     break;
      // }
    }, 
    (error) => {
      // Handle unsuccessful uploads
    }, 
    () => {
      // gets the functions from storage refences the image storage in firebase by the children
      // gets the download url then sets the image from firebase as the value for the imgUrl key:
      fireDb.storage().ref('images').child(returnName1).getDownloadURL()
       .then(fireBaseUrl => {
        //  setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
        console.log("fireBaseUrl", fireBaseUrl) 

        if (paramIndex === 0) {
          setImageLink0(fireBaseUrl);
          // 
          Firebase.database().ref()
          .child("behaviors/diary-checkins/" + textUploadId1).update({"avatar" : fireBaseUrl});
        } 
        if (paramIndex === 1) {
          setImageLink1(fireBaseUrl);
          // var tempArr = [];
          // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
          //   tempArr = [...objectUserData.photos]
          //   tempArr[paramIndex - 1] = fireBaseUrl
          // } else {
          //   tempArr = [fireBaseUrl]
          // }
          // Firebase.database().ref()
          // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
        }
        if (paramIndex === 2) {
          setImageLink2(fireBaseUrl);
          // var tempArr = [];
          // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
          //   tempArr = [...objectUserData.photos]
          //   tempArr[paramIndex - 1] = fireBaseUrl
          // } else {
          //   tempArr = [fireBaseUrl]
          // }
          // Firebase.database().ref()
          // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
        }
        if (paramIndex === 3) {
          setImageLink3(fireBaseUrl);
          // var tempArr = [];
          // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
          //   tempArr = [...objectUserData.photos]
          //   tempArr[paramIndex - 1] = fireBaseUrl
          // } else {
          //   tempArr = [fireBaseUrl]
          // }
          // Firebase.database().ref()
          // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
        }
        if (paramIndex === 4) {
          setImageLink4(fireBaseUrl);
          // var tempArr = [];
          // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
          //   tempArr = [...objectUserData.photos]
          //   tempArr[paramIndex - 1] = fireBaseUrl
          // } else {
          //   tempArr = [fireBaseUrl]
          // }
          // Firebase.database().ref()
          // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
        }
        if (paramIndex === 5) {
          setImageLink5(fireBaseUrl);
          // var tempArr = [];
          // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
          //   tempArr = [...objectUserData.photos]
          //   tempArr[paramIndex - 1] = fireBaseUrl
          // } else {
          //   tempArr = [fireBaseUrl]
          // }
          // Firebase.database().ref()
          // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
        }
        if (paramIndex === 6) {
          setImageLink6(fireBaseUrl);
          // var tempArr = [];
          // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
          //   tempArr = [...objectUserData.photos]
          //   tempArr[paramIndex - 1] = fireBaseUrl
          // } else {
          //   tempArr = [fireBaseUrl]
          // }
          // Firebase.database().ref()
          // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
        }
        if (paramIndex === 7) {
          setImageLink7(fireBaseUrl);
          // var tempArr = [];
          // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
          //   tempArr = [...objectUserData.photos]
          //   tempArr[paramIndex - 1] = fireBaseUrl
          // } else {
          //   tempArr = [fireBaseUrl]
          // }
          // Firebase.database().ref()
          // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
        }
        if (paramIndex === 8) {
          setImageLink8(fireBaseUrl);
          // var tempArr = [];
          // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
          //   tempArr = [...objectUserData.photos]
          //   tempArr[paramIndex - 1] = fireBaseUrl
          // } else {
          //   tempArr = [fireBaseUrl]
          // }
          // Firebase.database().ref()
          // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
        }
        if (paramIndex === 9) {
          setImageLink9(fireBaseUrl);
          // var tempArr = [];
          // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
          //   tempArr = [...objectUserData.photos]
          //   tempArr[paramIndex - 1] = fireBaseUrl
          // } else {
          //   tempArr = [fireBaseUrl]
          // }
          // Firebase.database().ref()
          // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
        }
        if (paramIndex === 10) {
          setImageLink10(fireBaseUrl);
          // var tempArr = [];
          // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
          //   tempArr = [...objectUserData.photos]
          //   tempArr[paramIndex - 1] = fireBaseUrl
          // } else {
          //   tempArr = [fireBaseUrl]
          // }
          // Firebase.database().ref()
          // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
        }
       })
    }
  );

}
    function handleSignUp() { 
      Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo(text1)
            .once("value", (snapshot) => {

                  console.log("handleSignUp - 1", snapshot)
                if (snapshot.val()) {
                    const data = snapshot.val();
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) { 
                        if (data[keyName].status == "YES") {
                            
                        } else {
                            // setBlockSystem(true);
                        }
                    } else {
                        //조건 분기 필요

                    }

                }  else {
                  
                  console.log("handleSignUp - 2", )
                  fireDb.database().ref().child("accounts/" + selectedUser.id ).update(
                    { 
                    username : text1,
                    nickname : text1,
                    password: text2,
                    firstname: text4,
                    lastname: text5,
                    nation: text7,
                    memo : text8,
                    theme : text9, 
                    habitat : text7, 
                    email : text1, 
                    year: text6, 
                    imageLink1 : imageLink1, 
                    imageLink2 : imageLink2, 
                    imageLink3 : imageLink3, 
                    imageLink4 : imageLink4, 
                    imageLink5 : imageLink5, 
                    imageLink6 : imageLink6, 
                    imageLink7 : imageLink7, 
                    imageLink8 : imageLink8, 
                    imageLink9 : imageLink9, 
                    imageLink10 : imageLink10, 
                    uuid : moment().format("YYYYMMDDHHmmssSSS"),
                    random_id16 : makeId(16),
                    random_id8 : makeId(8),
                    tag1 : selectedOption && selectedOption[0]  && selectedOption[0].value ? selectedOption[0].value : "",
                    tags : selectedOption && selectedOption.length > 0 ? selectedOption.filter((filterItem)=> {
                        return filterItem
                    }).map((filterItem)=> {
                        return filterItem.value
                    }).join("-") : "" 
                    }
                    , (err) => {
                    if (err) {
                      // toast.error(err);
                      console.log(err);
                    } else {
                        alert("수정되셨습니다. ")
                        // router.reload();
                        window.location.href = "/" 
                      
                    }
                  });
                }
            });
    
    }

    function makeId(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
  

    return ( 
        <div className={`${compString1}wrapper`}> 
            <div className={`${compString1}inner_wrapper`}> 
                
                <div className={`cursor_pointer ${compString1}button0  `}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if ( intStep1 > 1) {
                            setIntStep1(intStep1 - 1)
                        }
                    }}
                >
                    {
                        intStep1 != 1 &&
                        <img src={staticString5} alt='' style={{ width  : '20px', height : '20px', marginRight : '4px'}}/>
                    }
                    
                    Modify Info {intStep1} / {intStep0}
                    
                </div>
                <br/>
                <div className={` ${compString1}title0 `}>
                {
                        intStep1 == 1 &&
                        <>
                        </>
                    }
                    {
                        intStep1 == 2 &&
                        <>
                         Name
                        </>
                    }
                    {
                        intStep1 == 3 &&
                        <>
                            beginning
                        </>
                    }
                    {
                        intStep1 == 4 &&
                        <>
                            Theme?
                        </>
                    }
                    {
                        intStep1 == 5 &&
                        <>
                        habitat
                        </>
                    }
                    {
                        intStep1 == 6 &&
                        <>
                        notes
                        </>
                    }
                    {
                        intStep1 == 7 &&
                        <>
                        Media
                        </>
                    }

                </div>
            {
                intStep1 == 1 && <>
                    <div className={`${compString1}input01`}>
                        <div className={`${compString1}input01_input_paddingleft`} ></div>
                        <input className={`${compString1}input01_input`} value={text1} onChange={e=> {setText1(e.target.value)}} placeholder='ID : E-MAIL'></input>
                    </div>
                    <div className={`${compString1}input01`}> 
                        <div className={`${compString1}input01_input_paddingleft`} ></div> 
                        <input className={`${compString1}input01_input`} type={'password'} value={text2} onChange={e=> {setText2(e.target.value)}}  placeholder='PW : '></input>
                    </div>
                    <div className={`${compString1}input01`}> 
                        <div className={`${compString1}input01_input_paddingleft`} ></div> 
                        <input className={`${compString1}input01_input`} type={'password'} value={text3} onChange={e=> {setText3(e.target.value)}}  placeholder='PW(RE) : '></input>
                    </div>
                </>
                }
                {
                    intStep1 == 2 &&
                    <>
                    <div className={`${compString1}input01`}>
                        <div className={`${compString1}input01_input_paddingleft`} ></div>
                        <input className={`${compString1}input01_input`} value={text4} onChange={e=> {setText4(e.target.value)}} placeholder='first NAME'></input>
                    </div>
                    <div className={`${compString1}input01`}> 
                        <div className={`${compString1}input01_input_paddingleft`} ></div> 
                        <input className={`${compString1}input01_input`} value={text5} onChange={e=> {setText5(e.target.value)}}  placeholder='last NAME'></input>
                    </div>
                    </>
                }
                {
                    intStep1 == 3 &&
                    <>
                    <div className={`${compString1}input01`}>
                        <div className={`${compString1}input01_input_paddingleft`} ></div>
                        <input className={`${compString1}input01_input`} value={text6} onChange={e=> {setText6(e.target.value)}} placeholder='Beginning  Year'></input>
                    </div> 
                    </>
                }
                {
                    intStep1 == 4 &&
                    <div className={`${compString1}radio_wrapper0`}>
                        <div className={text9 == "nature" ? `${compString1}radio0 ${compString1}radio1` : `${compString1}radio0`} 
                            onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                setText9("nature")
                            }}
                        >nature</div>
                        <div className={text9 == "human" ? `${compString1}radio0 ${compString1}radio1` : `${compString1}radio0`} 
                            onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                setText9("human")
                            }}
                            >human</div>
                            <div className={text9 == "concept" ? `${compString1}radio0 ${compString1}radio1` : `${compString1}radio0`} 
                            onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                setText9("concept")
                            }}
                            >concept</div>
                    </div>
                }
                {
                    intStep1 == 5 &&
                    <>
                    <div className={`${compString1}input01`}>
                        <div className={`${compString1}input01_input_paddingleft`} ></div>
                        <input className={`${compString1}input01_input`} value={text7} onChange={e=> {setText7(e.target.value)}} placeholder='country or city'></input>
                    </div> 
                    </>
                }
                {
                    intStep1 == 6 &&
                    <>
                    <div className={`${compString1}input01`}>
                        <div className={`${compString1}input01_input_paddingleft`} ></div>
                        <input className={`${compString1}input01_input`} value={text8} onChange={e=> {setText8(e.target.value)}} placeholder='texts or  #KEYWORDs'></input>
                    </div> 
                    </>
                }
                {
                    intStep1 == 7 &&
                    <>
                        
                <div className='searchPopupWrapper'>

                <div className={`cursor_pointer ${compString1}button0  `}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if ( intStep1 > 1) {
                            setIntStep1(intStep1 - 1)
                        }
                    }}
                >
                    {
                        intStep1 != 1 &&
                        <img src={staticString5} alt='' style={{ width  : '20px', height : '20px', marginRight : '4px'}}/>
                    }
                    
                    Modify Info {intStep1} / {intStep0}
                </div>
                    <img className='toplogo' src={`/static/icon_3logo.png`} alt=''/>
                    <div className='title'>
                    Media
                    </div>
                    <Select
                    defaultValue={[]}
                    label="Select Tags"
                    // options={colourOptions}
                    styles={colourStyles} 
                    isMulti
                    value={selectedOption}
                    onChange={handleChange}
                    options={groupedOptions}

                    />  
                    <img className={`cursor_pointer ${compString1}img1`} src={staticString4} 
                      onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (intStep0 > intStep1 ) {
                              setIntStep1(intStep1 + 1) 
                          }
                          if (intStep0 == intStep1) {
                              if (text1 == "" || text2 == "" || text3 == "" || 
                                  text4 == "" || text5 == "" || text6 == "" || 
                                  text7 == "" || text8 == ""  
                              ) {
                                  alert("빈 입력값이 있습니다. 입력값을 확인해주세요.")
                                  return;
                              }
                              handleSignUp();
                          }
                      }}></img> 
                    {/* <Comp999SelectArea001/> */}
                    </div>
                    </>
                }
                {  intStep1 == 8 &&
                    <>

<div className="sectionLabel0 sectionLabel1">
                      {`업로드 이미지 (선택)`}
                  </div>
                  <div className="imagePlusRow0 imagePlusRow1">
                      
                    <div className="row0 row1">
      
      <input ref={imageRef0} 
              type="file" 
              onChange={(e)=>{
                
                setImageUpload0(e.target.files[0])
              }} 
              // accept="image/*"
               accept=".gif, .jpg, .png"
             // accept=".png"
              style={{display : 'none'}} alt="" />
              <button onClick={upload} style={{display : 'none'}}>Upload</button>
      
                  <input ref={imageRef1} 
                          type="file" 
                          onChange={(e)=>{setImageUpload1(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          <input ref={imageRef2} 
                          type="file" 
                          onChange={(e)=>{setImageUpload2(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef3} 
                          type="file" 
                          onChange={(e)=>{setImageUpload3(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef4} 
                          type="file" 
                          onChange={(e)=>{setImageUpload4(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef4} 
                          type="file" 
                          onChange={(e)=>{setImageUpload4(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
      
                          <input ref={imageRef5} 
                          type="file" 
                          onChange={(e)=>{setImageUpload5(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
      
                          <input ref={imageRef6} 
                          type="file" 
                          onChange={(e)=>{setImageUpload6(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef7} 
                          type="file" 
                          onChange={(e)=>{setImageUpload7(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef8} 
                          type="file" 
                          onChange={(e)=>{setImageUpload8(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef9} 
                          type="file" 
                          onChange={(e)=>{setImageUpload9(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef10} 
                          type="file" 
                          onChange={(e)=>{setImageUpload10(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
      
                  <div className="radius001_image_row_wrapper">
      
                  <div className="radius001_image_wrapper" 
                          style={{ 
                            background: `rgba(33, 37, 41, 0.7)`,
                            border : `none` ,
                          }}
                        >
                          {
                            // imageUpload1 == '' &&
                            <>
                            <div className="radius001_0" 
                              onClick={e=> {
                              e.preventDefault();
                              e.stopPropagation();
                              if (imageUpload1) {
                              } else {
                                imageRef1.current.click();
                                return;
                              }
                              if (imageUpload2) {
                              } else {
                                imageRef2.current.click();
                                return;
                              }
                              if (imageUpload3) {
                              } else {
                                imageRef3.current.click();
                                return;
                              }
                              if (imageUpload4) {
                              } else {
                                imageRef4.current.click();
                                return;
                              }
                              if (imageUpload5) {
                              } else {
                                imageRef5.current.click();
                                return;
                              }
                              if (imageUpload6) {
                              } else {
                                imageRef6.current.click();
                                return;
                              }
                              if (imageUpload7) {
                              } else {
                                imageRef7.current.click();
                                return;
                              }
                              if (imageUpload8) {
                              } else {
                                imageRef8.current.click();
                                return;
                              }
                              if (imageUpload9) {
                              } else {
                                imageRef9.current.click();
                                return;
                              }
                              if (imageUpload10) {
                              } else {
                                imageRef10.current.click();
                                return;
                              }
      
                            }}> 
                              <div className="radius001_desc0 cursor_pointer">사진 추가</div>
                            </div>
                            </>
                          }
                          <div>
                          </div>
                    </div>
      
                    {
                      imageLink1 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef1.current.click();
                      }}
                    >
                      {
                        imageLink1 !== '' &&
                        <>
                        <img className="full_image" src={imageLink1} alt=""/>
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                      imageLink2 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef2.current.click();
                      }}
                    >
                      {
                        imageLink2 !== '' &&
                        <>
                        <img className="full_image" src={imageLink2} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                      imageLink3 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef3.current.click();
                      }}
                    >
                      {
                        imageLink3 !== '' &&
                        <>
                        <img className="full_image" src={imageLink3} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                      imageLink4 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef4.current.click();
                      }}
                    >
                      {
                        imageLink4 !== '' &&
                        <>
                        <img className="full_image" src={imageLink4} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
      
      
      {
                      imageLink5 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef5.current.click();
                      }}
                    >
                      {
                        imageLink5 !== '' &&
                        <>
                        <img className="full_image" src={imageLink5} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                                    imageUpload6 !== '' && 
                    
                                    <div className="radius001_image_wrapper" 
                                    style={{ 
                                      background:  `transparent`,
                                      border : `1px solid #aeaeae`,
                                    }}
                                    onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      imageRef6.current.click();
                                    }}
                                  >
                                    {
                                      imageUpload6 !== '' &&
                                      <>
                                      <img className="full_image" src={imageLink6} alt="" />
                                      </>
                                    }
                                    <div>
                                    </div>
                              </div>
                                  }
                                  {
                              imageUpload7 !== '' && 
              
                              <div className="radius001_image_wrapper" 
                              style={{ 
                                background:  `transparent`,
                                border : `1px solid #aeaeae`,
                              }}
                              onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                imageRef7.current.click();
                              }}
                            >
                              {
                                imageUpload7 !== '' &&
                                <>
                                <img className="full_image" src={imageLink7} alt="" />
                                </>
                              }
                              <div>
                              </div>
                        </div>
                            }
                            {
                        imageUpload8 !== '' && 
        
                        <div className="radius001_image_wrapper" 
                        style={{ 
                          background:  `transparent`,
                          border : `1px solid #aeaeae`,
                        }}
                        onClick={e=> {
                          e.preventDefault();
                          e.stopPropagation();
                          imageRef8.current.click();
                        }}
                      >
                        {
                          imageUpload8 !== '' &&
                          <>
                          <img className="full_image" src={imageLink8} alt="" />
                          </>
                        }
                        <div>
                        </div>
                  </div>
                      }
                      {
                  imageUpload9 !== '' && 
      
                  <div className="radius001_image_wrapper" 
                  style={{ 
                    background:  `transparent`,
                    border : `1px solid #aeaeae`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    imageRef9.current.click();
                  }}
                >
                  {
                    imageUpload9 !== '' &&
                    <>
                    <img className="full_image" src={imageLink9} alt="" />
                    </>
                  }
                  <div>
                  </div>
            </div>
                }
      
      {
                  imageUpload10 !== '' && 
      
                  <div className="radius001_image_wrapper" 
                  style={{ 
                    background:  `transparent`,
                    border : `1px solid #aeaeae`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    imageRef9.current.click();
                  }}
                >
                  {
                    imageUpload10 !== '' &&
                    <>
                    <img className="full_image" src={imageLink10} alt="" />
                    </>
                  }
                  <div>
                  </div>
            </div>
                }
                      </div>
                      </div>
                  </div>
                    </>

                }

                  <img className={`cursor_pointer ${compString1}img1`} src={staticString4} 
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (intStep0 > intStep1 ) {
                            setIntStep1(intStep1 + 1) 
                        }
                        if (intStep0 == intStep1) {
                            if (text1 == "" || text2 == "" || text3 == "" || 
                                text4 == "" || text5 == "" || text6 == "" || 
                                text7 == "" || text8 == ""  
                            ) {
                                alert("빈 입력값이 있습니다. 입력값을 확인해주세요.")
                                return;
                            }
                            handleSignUp();
                        }
                    }}></img> 

                
            </div>        
            <style>{`
                .${compString1}wrapper {
                    position : fixed;
                    left: 50%;
                    top: 50%;
                    transform : translate(-50%, -50%);
                    z-index: 999;
                    
                    display: flex;
                    flex-direction : column;
                    align-items: center;
                    justify-content: center;


                }
                .${compString1}inner_wrapper {
                    
                    width : calc(100vw - 80px);
                    max-width : 800px;
                    min-height : 500px;
                    
                    display: flex;
                    flex-direction : column;
                    align-items: center;
                    justify-content: center;

                    background-color : #fff;
                    border-radius : 20px;

                
                }
                .${compString1}input01 {

                    width : calc(100% - 40px);
                    height : 50px;

                    border-radius : 20px;
                    background :  ${color2};
                    overflow : hidden;
                    display : flex;
                    flex-drection : row; 
                    border : 6px solid ${color1};
    
                    margin-bottom : 20px;
                }
                .${compString1}input01_input_paddingleft {
                    
                    margin-left : 10px;
                    margin-top: auto;
                    margin-right : 4px;
                    margin-bottom : auto;
                    height : calc(100% - 20px);
                    width : 20px;
                    color : ${color3};
                    background-color : ${color3};

                    
                }
                .${compString1}input01_input {
                    
                    margin-left: 20px;
                    width : calc(100% - 40px);
                    min-width : 200px;
                    border: none;
                    outline : none;
                    color : ${color3};
                    font-size: 20px;

                    background : transparent; 
                    
                }
    
                .${compString1}input01_input::placeholder { 
                    color : ${color3};
                    font-weight : 700;
                }
                .${compString1}button0  {

                    width : 200px;
                    height : 50px; 
                    overflow : hidden;
                    display : flex;
                    flex-drection : row; 
                    align-items: center;
                    justify-content: center; 

                    background : transparent;
                    border: none;
                    // border-bottom : 1px solid ${color1};
                    color : ${color1};
                    margin-top: 10px;
                    margin-bottom : 20px;

                }
                .${compString1}title0  {

                    width : 200px;
                    height : 30px; 
                    overflow : hidden;
                    display : flex;
                    flex-drection : row; 
                    align-items: center;
                    justify-content: center; 

                    background : transparent;
                    border: none;
                    // border-bottom : 1px solid ${color1};
                    color : ${color1};
                    margin-bottom : 20px;

                }
                .${compString1}img1 {
                    width : 48px;
                    height : 48px;   
                }
                .${compString1}radio_wrapper0 {
                    
                    display : flex;
                    flex-drection : row; 
                    align-items: center;
                    justify-content: center; 

                    width : 100%;
                    min-width : 200px;

                    margin-bottom : 40px;
                }

                .${compString1}radio0 {
                    
                    display : flex;
                    flex-drection : row; 
                    align-items: center;
                    justify-content: center; 
                    
                    font-size: 20px;
                    color : #fff;
                    background : ${color1};
                    width : 100px;
                    height : 40px;
                    border-radius: 20px;

                    margin-left: 10px;
                    margin-right : 10px;
                    cursor: pointer;
                }
                .${compString1}radio1 {
                    color : #2DFF0B;
                    font-weight : 700;
                    
                }


                .radius001_image_row_wrapper {
                    width : 100%;
                    overflow-x : scroll;
                    overflow: scroll;
                
                    display: flex;
                    flex-direction: row;
                    /* align-items: center;
                    justify-content: center; */
                
                    align-items: flex-start;
                    justify-content: flex-start;
                
                    max-width: 100vw;
                    overflow: scroll;
                }
                
                .radius001_image_wrapper {
                    width : 100px;
                    height : 100px;
                    min-width : 100px;
                    min-height : 100px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                
                
                    overflow: hidden;
                }
                
                .radius001_image_row_wrapper 
                .radius001_image_wrapper {
                    margin-top: 10px;
                    margin-bottom : 30px;
                    margin-left: 20px;
                }
                
                
                /* width */
                .radius001_image_row_wrapper::-webkit-scrollbar {
                    width: 0px;
                    height: 0px;
                    display: none;
                  }
                  
                  /* Track */
                  .radius001_image_row_wrapper::-webkit-scrollbar-track {
                    background: #f1f1f1; 
                    width: 0px;
                    height: 0px;
                    display: none;
                  }
                  
                  /* Handle */
                  .radius001_image_row_wrapper::-webkit-scrollbar-thumb {
                    background: #181818; 
                    border-radius : 20px;
                    width: 0px;
                    height: 0px;
                    display: none;
                  }
                  
                  /* Handle on hover */
                  .radius001_image_row_wrapper::-webkit-scrollbar-thumb:hover {
                    background: #555; 
                    width: 0px;
                    height: 0px;
                    display: none;
                  }
                  
                  .radius001_0 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  }
                
                
                
                .radius001_image0 {
                    width : 20px;
                    height : 20px;
                    object-fit: contain;
                    
                    margin-left: auto;
                    margin-right : auto;
                }
                
                .workItemImg {
                    width : 100%;
                    max-height: 300px;
                    object-fit : contain;

                    margin-bottom : 40px;
                }
                .${compString1}item0 {
                    width : 300px;
                    max-width : 300px;

                    display: flex;

                    color : ${color1};
                    margin-top: 4px;
                    margin-bottom: 4px;

                    font-size: 20px;

                }
                .${compString1}workItem0 {
                    
                    width : 100%;
                    max-height: 300px;
                    margin-bottom : 20px;
                }
                .${compString1}workItem0  > .${compString1}text1 {

                    width : 300px;
                    max-width : 300px;

                    display: flex;
                    font-size: 18px;
                    align-items: center;
                    justify-content: center;

                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom : 8px;

                }
            `}</style>
        </div>
  );
}

export default Comp;
