import logo from './logo.svg';
import './App.css';
import React, { Component, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { TagCloud } from "@frank-mayer/react-tag-cloud";
// import firebase from "../firebase";
// import fireDb from "../firebase";
import Comp001TopNav from "./components/Comp001TopNav";
import Comp002CenterNetMap from "./components/Comp002CenterNetMap";
import Comp003BottomNav from "./components/Comp003BottomNav";
import Comp010LoginPopup from "./components/Comp010LoginPopup";
import Comp011SignupPopup from "./components/Comp011SignupPopup";
import Comp021ModifyPopup from "./components/Comp021ModifyPopup";
import Comp020SamplePopup from "./components/Comp020SamplePopup";
import Comp030CreatePostPopup from "./components/Comp030CreatePostPopup";
import Comp040ViewPostPopup from "./components/Comp040ViewPostPopup"
// 
// import Comp999SelectArea001 from "./components/Comp999SelectArea001";
import PopupBlur1 from "./components/PopupBlur1"; 
import PopupBlur2 from "./components/PopupBlur2"; 
import PopupFull1 from "./components/PopupFull1";  
import * as  THREE from "three"
import SpriteText from 'three-spritetext';

import Select from 'react-select'
import { ForceGraph2D, ForceGraph3D, ForceGraphVR, ForceGraphAR } from 'react-force-graph'; 
 
import QuillWrapper from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { CSS2DRenderer, CSS2DObject } from './CSS2DRenderer';


import Firebase from 'firebase';
import moment from "moment";
// import uuid from 'react-uuid';
import fireDb from "./firebase";
import firebase from "./firebase";

const color1 = `#AE47FF`;
const color2 = `#FEFDFF`;
const color3 = `#FFFFFF`;
const compString1 = `totalWrapper_`

const colourOptions0 = [
  { value: "red", label: "Red" },
  { value: "green", label: "Green" },
  { value: "blue", label: "Blue" }
];

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#999999" : null,
      color: "#333333",
      outerWidth : "600px",
      innerWidth : "600px",
      
      
    };
  }
};


const categoryOptions1 = [

{ value: "디지털그래픽 CGI(2D/3D)	computer-generated-imagery-2d-3d", label: "디지털그래픽 CGI(2D/3D)	computer-generated-imagery-2d-3d", color : "#AE47FF", },
{ value: "웹툰/만화 Webtoon/Comics	webtoon-comics", label: "웹툰/만화 Webtoon/Comics	 ", color : "#AE47FF", },
{ value: "일러스트 Illustration	illustration", label: "일러스트 Illustration	 ", color : "#AE47FF", },
{ value: "캐릭터디자인 Character Design	character-design", label: "캐릭터디자인 Character Design	 ", color : "#AE47FF", },
{ value: "캘리그래피 Calligraphy	calligraphy", label: "캘리그래피 Calligraphy	 ", color : "#AE47FF", },
{ value: "타투디자인 Tatoo Design	tatoo-design", label: "타투디자인 Tatoo Design	 ", color : "#AE47FF", },
{ value: "패션일러스트 Fashion Illustration	fashion-illustration", label: "패션일러스트 Fashion Illustration ", color : "#AE47FF", },
{ value: "패턴디자인 Pattern Design	pattern-design", label: "패턴디자인 Pattern Design ", color : "#AE47FF", },
{ value: "피규어디자인 Art Toy	art-toy", label: "피규어디자인 Art Toy ", color : "#AE47FF", },
];//9

const categoryOptions2 = [
{ value : "사진 Photography	photography", label :  "사진 Photography ", color : "#AE47FF", },
{ value : "조각 Sculpture	sculpture", label :  "조각 Sculpture ", color : "#AE47FF", },
{ value : "판화 Print	print", label :  "판화 Print ", color : "#AE47FF", },
{ value : "회화 Painting	painting", label :  "회화 Painting ", color : "#AE47FF", },
];

const categoryOptions3 = [
{ value: "모션그래픽 Motion Graphic	motiongraphic", label: "모션그래픽 Motion Graphic", color : "#AE47FF",},
{ value: "미디어아트 Media Art	media-art", label: "미디어아트 Media Art ", color : "#AE47FF",},
{ value: "애니메이션 Animation	animation", label: "애니메이션 Animation ", color : "#AE47FF",},
{ value: "영화 Film Production	Film Production", label: "영화 Film Production ", color : "#AE47FF",},
]; 
 
const themeOptions1 = [
  { value: "nature", label: "nature", rating: "nature" },
  { value: "human", label: "human", rating: "human" },
  { value: "concept", label: "concept", rating: "concept" }
  ];
  


  const flavourOptions = [
    { value: "vanilla", label: "Vanilla", rating: "safe" },
    { value: "chocolate", label: "Chocolate", rating: "good" },
    { value: "strawberry", label: "Strawberry", rating: "wild" }
    ];
      


const groupedOptions = [
  {
    label: "그래픽아트 Graphic Art	Graphic Art",
    options: categoryOptions1
  }, 
  {
    label: "순수미술 Fine Art	fine-art",
    options: categoryOptions2
  }, 
  {
    label: "영상 Video Graphic	videoart",
    options: categoryOptions3
  },  
];


const myData = {
  "nodes": [
    // {"id": "Myriel", "group": 1, "color" : color1}, 
  ],
  "links": [ 
  ]
} 
    const NODE_R = 6;
function App() {
  const extraRenderers = [new CSS2DRenderer()];
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [objectPopupData1, setObjectPopupData1] = useState(null);
 
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [textInput1, setTextInput1] = useState("");
    const [textInput2, setTextInput2] = useState("");
    const [textInput3, setTextInput3] = useState("");
    const [textInput4, setTextInput4] = useState("");

    const [textInput11 , setTextInput11] = useState("");
    const [textInput12 , setTextInput12] = useState("");
    const [textInput13 , setTextInput13] = useState("");
    const [textInput14 , setTextInput14] = useState("");
    const [textInput15 , setTextInput15] = useState("");
    const [textInput16 , setTextInput16] = useState("male");
    const [textInput17 , setTextInput17] = useState("");
    const [textInput18 , setTextInput18] = useState("");
    const [textInput19 , setTextInput19] = useState("");
    const [boolCheck1, setBoolCheck1] = useState(false);
    const [boolCheck2, setBoolCheck2] = useState(false);
    const [boolCheck3, setBoolCheck3] = useState(false);
    const [boolCheck4, setBoolCheck4] = useState(false);
    const [imageUpload1, setImageUpload1] = useState('');
    const [imageLink, setImageLink] = useState('');
    const [textInput21 , setTextInput21] = useState("");
    const [textInput22 , setTextInput22] = useState("");
 

    const [boolOpenPopup1, setBoolOpenPopup1] = useState(false);
    const [boolOpenPopup2, setBoolOpenPopup2] = useState(false);
    const [boolOpenPopup3, setBoolOpenPopup3] = useState(false);
    const [boolOpenPopup4, setBoolOpenPopup4] = useState(false);
    const [boolOpenPopup5, setBoolOpenPopup5] = useState(false);
    const [boolOpenPopup6, setBoolOpenPopup6] = useState(false);


    const [boolOpenPopup10, setBoolOpenPopup10] = useState(false);
    const [boolOpenPopup11, setBoolOpenPopup11] = useState(false);
    const [boolOpenPopup12, setBoolOpenPopup12] = useState(false);
    const [selectedNode0, setSelectedNode0] = useState();
    const [selectedNodes1, setSelectedNodes1] = useState([]);

    const [textColor1, setTextColor1] = useState("#000")

    const [nodeList,setNodeList,] = useState([])
    const [nodeList1,setNodeList1,] = useState([])
    const [nodeList2,setNodeList2,] = useState([])
    const [nodeList3,setNodeList3,] = useState([])
    const [nodeList4,setNodeList4,] = useState([])
    const [nodeList5,setNodeList5,] = useState([])
    const [nodeList6,setNodeList6,] = useState([])
    const [nodeList7,setNodeList7,] = useState([])
    const [nodeList8,setNodeList8,] = useState([])
    const [nodeList9,setNodeList9,] = useState([])
    const [nodeList10,setNodeList10,] = useState([])
    const [nodeList11,setNodeList11,] = useState([])
    const [nodeList12,setNodeList12,] = useState([])
    const [nodeList13,setNodeList13,] = useState([])
    const [nodeList14,setNodeList14,] = useState([])
    const [nodeList15,setNodeList15,] = useState([])
    const [nodeList16,setNodeList16,] = useState([])
    const [nodeList17,setNodeList17,] = useState([])
    const [nodeList18,setNodeList18,] = useState([])
    const [nodeList19,setNodeList19,] = useState([])

    const [nodeList20,setNodeList20,] = useState([])
    const [nodeList21,setNodeList21,] = useState([])
    const [nodeList22,setNodeList22,] = useState([])



    const [nodeIdOnlyList1,setnodeIdOnlyList1,] = useState([])
    const [nodeIdOnlyList2,setnodeIdOnlyList2,] = useState([])
    const [nodeIdOnlyList3,setnodeIdOnlyList3,] = useState([])
    const [nodeIdOnlyList4,setnodeIdOnlyList4,] = useState([])
    const [nodeIdOnlyList5,setnodeIdOnlyList5,] = useState([])
    const [nodeIdOnlyList6,setnodeIdOnlyList6,] = useState([])
    const [nodeIdOnlyList7,setnodeIdOnlyList7,] = useState([])
    const [nodeIdOnlyList8,setnodeIdOnlyList8,] = useState([])
    const [nodeIdOnlyList9,setnodeIdOnlyList9,] = useState([])
    const [nodeIdOnlyList10,setnodeIdOnlyList10,] = useState([])
    const [nodeIdOnlyList11,setnodeIdOnlyList11,] = useState([])
    const [nodeIdOnlyList12,setnodeIdOnlyList12,] = useState([])
    const [nodeIdOnlyList13,setnodeIdOnlyList13,] = useState([])
    const [nodeIdOnlyList14,setnodeIdOnlyList14,] = useState([])
    const [nodeIdOnlyList15,setnodeIdOnlyList15,] = useState([])
    const [nodeIdOnlyList16,setnodeIdOnlyList16,] = useState([])
    const [nodeIdOnlyList17,setnodeIdOnlyList17,] = useState([])
    const [nodeIdOnlyList18,setnodeIdOnlyList18,] = useState([])
    const [nodeIdOnlyList19,setnodeIdOnlyList19,] = useState([])
    
    const [nodeIdOnlyList20,setnodeIdOnlyList20,] = useState([])
    const [nodeIdOnlyList21,setnodeIdOnlyList21,] = useState([])
    const [nodeIdOnlyList22,setnodeIdOnlyList22,] = useState([])
    const [nodeIdOnlyList23,setnodeIdOnlyList23,] = useState([])
    const [nodeIdOnlyList24,setnodeIdOnlyList24,] = useState([])
    const [nodeIdOnlyList25,setnodeIdOnlyList25,] = useState([])
    const [nodeIdOnlyList26,setnodeIdOnlyList26,] = useState([])
    const [nodeIdOnlyList27,setnodeIdOnlyList27,] = useState([])
    const [nodeIdOnlyList28,setnodeIdOnlyList28,] = useState([])
    const [nodeIdOnlyList29,setnodeIdOnlyList29,] = useState([])

    const [nodeIdOnlyList30,setnodeIdOnlyList30,] = useState([])
    const [nodeIdOnlyList31,setnodeIdOnlyList31,] = useState([])
    const [nodeIdOnlyList32,setnodeIdOnlyList32,] = useState([])
    const [nodeIdOnlyList33,setnodeIdOnlyList33,] = useState([])
    const [nodeIdOnlyList34,setnodeIdOnlyList34,] = useState([])
    const [nodeIdOnlyList35,setnodeIdOnlyList35,] = useState([])
    const [nodeIdOnlyList36,setnodeIdOnlyList36,] = useState([])
    const [nodeIdOnlyList37,setnodeIdOnlyList37,] = useState([])
    const [nodeIdOnlyList38,setnodeIdOnlyList38,] = useState([])
    const [nodeIdOnlyList39,setnodeIdOnlyList39,] = useState([])


    const [linkList,setLinkList,] = useState([])
    const [linkList1,setLinkList1,] = useState([])
    const [linkList2,setLinkList2,] = useState([])
    const [linkList3,setLinkList3,] = useState([])
    const [linkList4,setLinkList4,] = useState([])
    const [linkList5,setLinkList5,] = useState([])
    const [linkList6,setLinkList6,] = useState([])
    const [linkList7,setLinkList7,] = useState([])
    const [linkList8,setLinkList8,] = useState([])
    const [linkList9,setLinkList9,] = useState([])
    const [linkList10,setLinkList10,] = useState([])
    const [linkList11,setLinkList11,] = useState([])
    const [linkList12,setLinkList12,] = useState([])
    const [linkList13,setLinkList13,] = useState([])
    const [linkList14,setLinkList14,] = useState([])
    const [linkList15,setLinkList15,] = useState([])
    const [linkList16,setLinkList16,] = useState([])
    const [linkList17,setLinkList17,] = useState([])
    const [linkList18,setLinkList18,] = useState([])
    const [linkList19,setLinkList19,] = useState([])

    const [linkList20,setLinkList20,] = useState([])
    const [linkList21,setLinkList21,] = useState([])
    const [linkList22,setLinkList22,] = useState([])
    const [linkList23,setLinkList23,] = useState([])
    const [linkList24,setLinkList24,] = useState([])
    const [linkList25,setLinkList25,] = useState([])
    const [linkList26,setLinkList26,] = useState([])
    const [linkList27,setLinkList27,] = useState([])
    const [linkList28,setLinkList28,] = useState([])
    const [linkList29,setLinkList29,] = useState([])

    const [linkList30,setLinkList30,] = useState([])
    const [linkList31,setLinkList31,] = useState([])
    const [linkList32,setLinkList32,] = useState([])
    const [linkList33,setLinkList33,] = useState([])
    const [linkList34,setLinkList34,] = useState([])
    const [linkList35,setLinkList35,] = useState([])
    const [linkList36,setLinkList36,] = useState([])
    const [linkList37,setLinkList37,] = useState([])
    const [linkList38,setLinkList38,] = useState([])
    const [linkList39,setLinkList39,] = useState([])


    const [selectedOption, setSelectedOption] = useState();
    const [textLoggedIn, setTextLoggedIn] = useState("");


    function handleChange(selectedOption) {
      console.log("selectedOption", selectedOption);

      setSelectedOption(selectedOption) ;
  };


    function makeId(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
     
  useEffect(() => {

  
    const authStoreKeyName = localStorage.getItem("authStoreKeyName");
    const authStoreUserName = localStorage.getItem("authStoreUserName");
    const authStore1 = localStorage.getItem("authStore")
    

    // console.log("authStoreUserName", authStoreUserName)
    if (authStoreUserName && authStoreUserName != "") {
        setTextLoggedIn(authStoreUserName)
    } 

    // .child("behaviors/diary-checkins")
    // // .orderByChild("username")
    // // .equalTo(text1)
    const todoRef = firebase.database()
    // .ref("behaviors/diary-checkins");
    .ref("accounts");

    todoRef.on("value", (snapshot) => {
      const todos = snapshot.val(); 
      const todoList = [];
      for (let id in todos) {
        // console.log("id", id)
        todoList.push({ id, name : id,  ...todos[id] });
      } 
      setNodeList(todoList);
      // console.log("todoList->", todoList);
    });

    if (false) {
      for(var i=0; i< 10; i++) {
        var preFix = 20;
        var tempStr1 = `emailtest${preFix + i}@naver.com`
        fireDb.database().ref().child("accounts").push(
          {
          //   check_agree1 : boolCheck1,
          //   check_agree2 : boolCheck2,
          //   check_agree3 : boolCheck3,
          //   check_agree4 : boolCheck4,
          //   nation : textInput1,  //"in" 내국인, "out" 외국인
            // 지금수정중
          //   parent_name : textInput2,
          //   contact_number : textInput3,
          //   contact_secret : textInput4,
          //   profile_image : imageLink,
          username : tempStr1,
          nickname : tempStr1,
          password: tempStr1,
          firstname: tempStr1,
          lastname: tempStr1,
          nation: tempStr1,
          memo : tempStr1,
          email : tempStr1,
          //   gender : textInput16, // "male", "female"
          //   usertype : textInput17,
          //   birthdate : textInput18,
          //   grade : textInput19, 
          uuid : moment().format("YYYYMMDDHHmmssSSS"),
          random_id16 : makeId(16),
          random_id8 : makeId(8),
          tags : categoryOptions1[0]
          // filter((filterItem)=> {
          //     return filterItem.value
          // })
          // .map((filterItem)=> {
          //     return filterItem.value
          // }).join("-")
          //   restoration_code : textInput21,

            // account : textInput1, 
            // email : textInput1, 
            // password : textInput2,
          }
          , (err) => {
          if (err) {
            // toast.error(err);
            console.log(err);
          } else {
              // alert("가입되셨습니다. ")
              // // router.reload();
              // window.location.href = "/"
            // toast.success("Signup  Successfully");

          //   setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}> 회원가입되었습니다.</div>)

            // setTimeout(()=> {
            //     const pageCodeGo1 =  "page000/0/login";
            //     setScreenMode1(pageCodeGo1)
            // }, 1500)

            // alert("")
            
          }
        });
      }  
    }


  }, []);

  useEffect(()=> {
    const stringArr1 = selectedOption && selectedOption.filter((filterItem)=> {
      return filterItem
  }).map((filterItem)=> {
      return filterItem.value
  })

  var linkList1 = [];
  // console.log("stringArr1", stringArr1)

  if (selectedOption && selectedOption.length > 0) {

    for (var i=0;i< selectedOption.length;i++) {

      if (nodeList && nodeList.length > 0) {
        const arr1 = nodeList.filter((filterItem, filterIndex)=> {
          if (filterItem.tag1 && String(filterItem.tag1).indexOf(selectedOption[i].value)  > -1) { 
            return filterItem
          }
        }) 

        setNodeList(arr1)
        
        // console.log("arr1", arr1)

  
      }
    }

  }
  // setLinkList(linkList1)


  // console.log("arr1", arr1)
  // var linkList1 = [];
  // for(var i =0 ;i < arr1.length; i++) {
  //   for(var j =0 ;j < arr1.length; j++) {
  //     linkList1.push({"source": arr1[i].id, "target":  arr1[j].id, "value": 1}) 
  //   }
  // }

  // for(var i =0 ;i < arr2.length; i++) {
  //   for(var j =0 ;j < arr2.length; j++) {
  //     linkList1.push({"source": arr2[i].id, "target":  arr2[j].id, "value": 1}) 
  //   }
  // }
  // for(var i =0 ;i < arr3.length; i++) {
  //   for(var j =0 ;j < arr3.length; j++) {
  //     linkList1.push({"source": arr3[i].id, "target":  arr3[j].id, "value": 1}) 
  //   }
  // }
  // for(var i =0 ;i < arr4.length; i++) {
  //   for(var j =0 ;j < arr4.length; j++) {
  //     linkList1.push({"source": arr4[i].id, "target":  arr4[j].id, "value": 1}) 
  //   }
  // }

  // setLinkList(linkList1)

  }, [ selectedOption])

  useEffect(()=> {

    var arr1 = nodeList;
    var tempLinkList1 = [];
    var tempLinkList2 = [];
    var tempLinkList3 = [];
    var tempLinkList4 = [];
    var tempLinkList5 = [];
    var tempLinkList6 = [];
    var tempLinkList7 = [];
    var tempLinkList8 = [];
    var tempLinkList9 = [];
    var tempLinkList10 = [];
    var tempLinkList11 = [];
    var tempLinkList12 = [];
    var tempLinkList13 = [];
    var tempLinkList14 = [];
    var tempLinkList15 = [];
    var tempLinkList16 = [];
    var tempLinkList17 = [];
    var tempLinkList18 = [];
    var tempLinkList19 = [];

    var tempNodeList1 = [];
    var tempNodeList2 = [];
    var tempNodeList3 = [];
    var tempNodeList4 = [];
    var tempNodeList5 = [];
    var tempNodeList6 = [];
    var tempNodeList7 = [];
    var tempNodeList8 = [];
    var tempNodeList9 = [];
    var tempNodeList10 = [];
    var tempNodeList11 = [];
    var tempNodeList12 = [];
    var tempNodeList13 = [];
    var tempNodeList14 = [];
    var tempNodeList15 = [];
    var tempNodeList16 = [];
    var tempNodeList17 = [];
    var tempNodeList18 = [];
    var tempNodeList19 = [];
    
    
    var tempNodeList20 = [];
    var tempNodeList21 = [];
    var tempNodeList22 = [];
    var tempNodeList23 = [];
    var tempNodeList24 = [];
    var tempNodeList25 = [];
    var tempNodeList26 = [];
    var tempNodeList27 = [];
    var tempNodeList28 = [];
    var tempNodeList29 = [];

    // for (var i=0; i < nodeList.length; i++) {
      // for (var j=0;j < categoryOptions1.length; j++) {
      // }
      // // 9
      // for (var j=0;j < categoryOptions2.length; j++) {
        
      // }
      // // 4
      // for (var j=0;j < categoryOptions3.length; j++) {
        
      // } 
      // 4
    // }


    tempNodeList1 = nodeList.filter((filterItem)=> { 
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions1[0].value) > -1) {
        return filterItem
      } 
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 1, 
        
      }
    })
    tempNodeList2 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions1[1].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 2, 
      }
    })
    tempNodeList3 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions1[2].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 3, 
      }
    })
    tempNodeList4 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions1[3].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 4, 
      }
    })
    tempNodeList5 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions1[4].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 5, 
      }
    })
    tempNodeList6 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions1[5].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 6, 
      }
    })
    tempNodeList7 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions1[6].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 7, 
      }
    })
    tempNodeList8 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions1[7].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 8, 
      }
    })
    tempNodeList9 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions1[8].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 9, 
      }
    })
    // 9
    tempNodeList10 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions2[0].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 10, 
      }
    })
    tempNodeList11 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions2[1].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 11, 
      }
    })
    tempNodeList12 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions2[2].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 12, 
      }
    })
    tempNodeList13 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions2[3].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 13, 
      }
    })
    // 
    tempNodeList14 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions3[0].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 14, 
      }
    })
    tempNodeList15 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions3[1].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 15, 
      }
    })
    tempNodeList16 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions3[2].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 16, 
      }
    })
    tempNodeList17 = nodeList.filter((filterItem)=> {
      if (filterItem &&  filterItem.tags && filterItem.tags.indexOf(categoryOptions3[3].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 17, 
      }
    })


    tempNodeList20 = themeOptions1.filter((filterItem)=> {
      if (filterItem &&  filterItem.theme && filterItem.theme.indexOf(themeOptions1[0].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 17, 
      }
    })
    tempNodeList21 = themeOptions1.filter((filterItem)=> {
      if (filterItem &&  filterItem.theme && filterItem.theme.indexOf(themeOptions1[1].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 17, 
      }
    })
    tempNodeList22 = themeOptions1.filter((filterItem)=> {
      if (filterItem &&  filterItem.theme && filterItem.theme.indexOf(themeOptions1[2].value) > -1)
      return filterItem
    }).map((mapItem)=> {
return Object.assign(mapItem, {color : color1})
      return { 
         id : "Node" + mapItem.id, name : mapItem.id, color : color1, 

        contents : mapItem.contents, 
        imageLink1 : mapItem.imageLink1, 
        title : mapItem.title, 
        username : mapItem.username, 
        group : 17, 
      }
    })

    
    // for (var i=0;i< tempNodeList1.length; i++) {
    //   for (var j=0;j< 4; j++) {
    //     tempLinkList1.push({"source": tempNodeList1[i].id, "target":  tempNodeList1[j].id, "value": 1}) 
    //   }
    // }

    // console.log("tempNodeList2",tempNodeList2)
    // for (var i=0;i< tempNodeList2.length; i++) {
    //   for (var j=0;j< 4; j++) {
    //     // console.log("tempNodeList2[i].id", tempNodeList2[i].id)
    //     tempLinkList1.push({"source": tempNodeList2[i].id, "target":  tempNodeList2[j].id, "value": 1}) 
    //   }
    // }


    // tempLinkList1.push({"source": arr1[k].id, "target":  arr1[j].id, "value": 1}) 

    // console.log("nodeList", nodeList)



    // setLinkList(linkList1)
    setNodeList1(tempNodeList1)
    setNodeList2(tempNodeList2)
    setNodeList3(tempNodeList3)
    setNodeList4(tempNodeList4)
    setNodeList5(tempNodeList5)
    setNodeList6(tempNodeList6)
    setNodeList7(tempNodeList7)
    setNodeList8(tempNodeList8)
    setNodeList9(tempNodeList9)
    setNodeList10(tempNodeList10) 
    setNodeList11(tempNodeList11)
    setNodeList12(tempNodeList12)
    setNodeList13(tempNodeList13)
    setNodeList14(tempNodeList14)
    setNodeList15(tempNodeList15)
    setNodeList16(tempNodeList16)
    setNodeList17(tempNodeList17)


    setNodeList20(tempNodeList20) 
    setNodeList21(tempNodeList21)
    setNodeList22(tempNodeList22)


    setnodeIdOnlyList1(tempNodeList1.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList2(tempNodeList2.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList3(tempNodeList3.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList4(tempNodeList4.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList5(tempNodeList5.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList6(tempNodeList6.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList7(tempNodeList7.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList8(tempNodeList8.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList9(tempNodeList9.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList10(tempNodeList10.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList11(tempNodeList11.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList12(tempNodeList12.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList13(tempNodeList13.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList14(tempNodeList14.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList15(tempNodeList15.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList16(tempNodeList16.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList17(tempNodeList17.map((mapItem=> {return mapItem.id})))

    setnodeIdOnlyList20(tempNodeList20.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList21(tempNodeList21.map((mapItem=> {return mapItem.id})))
    setnodeIdOnlyList22(tempNodeList22.map((mapItem=> {return mapItem.id})))


    // 
    // Theme(4)
    // habitat(5)
    // notes(6)

    // setLinkList([{source:  0, target:  1, distance: 2}, ])

  }, [nodeList])
 
  useEffect(()=> {
    
    if (nodeList1 && nodeList1.length == 0) {
      return;
    }
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList1.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList1[i] && nodeIdOnlyList1[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList1[i], "target": nodeIdOnlyList1[j], "value": 8, connectingValue : 1}) 
        }
      }
    }
    setLinkList1(tempLinkList1)

  }, [nodeIdOnlyList1])
  useEffect(()=> {

    if (nodeList2 && nodeList2.length == 0) {
      return;
    } 
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList2.length; i++) {
      for (var j=0;j< 4; j++) { 
        if (nodeIdOnlyList2[i] && nodeIdOnlyList2[j]) {
          if (nodeIdOnlyList2[i] && nodeIdOnlyList2[j]) {
            tempLinkList1.push({"source": nodeIdOnlyList2[i], "target": nodeIdOnlyList2[j], "value": 8, connectingValue : 2}) 
          }

        }
      }
    } 
    setLinkList2(tempLinkList1)
  }, [nodeIdOnlyList2]) 
  useEffect(()=> {
    if (nodeList3 && nodeList3.length == 0) {
      return;
    }
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeList3.length; i++) {
      for (var j=0;j< 4; j++) {

        if (nodeIdOnlyList3[i] && nodeIdOnlyList3[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList3[i], "target":  nodeIdOnlyList3[j], "value": 1, connectingValue : 3}) 
        }
      }
    }
    setLinkList3(tempLinkList1)

  }, [nodeList3])

  useEffect(()=> {
    if (nodeList4 && nodeList4.length == 0) {
      return;
    }
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeList4.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList4[i] && nodeIdOnlyList4[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList4[i], "target":  nodeIdOnlyList4[j], "value": 1, connectingValue : 4}) 
        }
      }
    }
    setLinkList3(tempLinkList1)

  }, [nodeIdOnlyList4])

  useEffect(()=> {
    if (nodeList5 && nodeList5.length == 0) {
      return;
    }
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeList5.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList5[i] && nodeIdOnlyList5[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList5[i], "target":  nodeIdOnlyList5[j], "value": 1, connectingValue : 5}) 
        }
      }
    }
    setLinkList5(tempLinkList1)

  }, [nodeIdOnlyList5])

  useEffect(()=> {

    if (nodeList6 && nodeList6.length == 0) {
      return;
    }
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeList6.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList6[i] && nodeIdOnlyList6[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList6[i], "target":  nodeIdOnlyList6[j], "value": 1, connectingValue : 6}) 
        }
      }
    }
    setLinkList6(tempLinkList1)
  }, [nodeIdOnlyList6])

  useEffect(()=> {

    if (nodeIdOnlyList7 && nodeIdOnlyList7.length == 0) {
      return;
    }
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList7.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList7[i] && nodeIdOnlyList7[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList7[i], "target":  nodeIdOnlyList7[j], "value": 1, connectingValue : 7}) 
        }
      }
    }
    setLinkList7(tempLinkList1)
  }, [nodeIdOnlyList7])



  useEffect(()=> {

    if (nodeList8 && nodeList8.length == 0) {
      return;
    }

    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList8.length; i++) {
      for (var j=0;j< 4; j++) { 
        if (nodeIdOnlyList8[i] && nodeIdOnlyList8[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList8[i], "target":  nodeIdOnlyList8[j], "value": 1, connectingValue : 8})  
        }
      }
    }
    setLinkList8(tempLinkList1)
  }, [nodeIdOnlyList8])
  
  useEffect(()=> {

    if (nodeList9 && nodeList9.length == 0) {
      return;
    }

    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList9.length; i++) {
      for (var j=0;j< 4; j++) { 

        if (nodeIdOnlyList9[i] && nodeIdOnlyList9[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList9[i], "target":  nodeIdOnlyList9[j], "value": 1, connectingValue : 9})  
        }
      }
    }
    setLinkList9(tempLinkList1)
  }, [nodeIdOnlyList9])

  useEffect(()=> {

    if (nodeList10 && nodeList10.length == 0) {
      return;
    }
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeList10.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList10[i] && nodeIdOnlyList10[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList10[i], "target":  nodeIdOnlyList10[j], "value": 1, connectingValue : 10})  
        }
      }
    }
    setLinkList10(tempLinkList1)
  }, [nodeIdOnlyList10])


  useEffect(()=> {

    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeList11.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList11[i] && nodeIdOnlyList11[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList11[i], "target":  nodeIdOnlyList11[j], "value": 1, connectingValue : 11})  
        }
      }
    }
    setLinkList11(tempLinkList1)
  }, [nodeIdOnlyList11])

  useEffect(()=> {
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList12.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList12[i] && nodeIdOnlyList12[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList12[i], "target":  nodeIdOnlyList12[j], "value": 1, connectingValue : 12}) 
        }
      }
    }
    setLinkList12(tempLinkList1)

  }, [nodeIdOnlyList12])

  useEffect(()=> {
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList13.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList13[i] && nodeIdOnlyList13[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList13[i], "target":  nodeIdOnlyList13[j], "value": 1, connectingValue : 13}) 
        }
      }
    }
    setLinkList13(tempLinkList1)

  }, [nodeIdOnlyList13])
 
  useEffect(()=> {

    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeList14.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList14[i] && nodeIdOnlyList14[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList14[i], "target":  nodeIdOnlyList14[j], "value": 1, connectingValue : 14}) 
        }
      }
    }
    setLinkList14(tempLinkList1)
  }, [nodeList14])

  useEffect(()=> {

    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList15.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList15[i] && nodeIdOnlyList15[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList15[i], "target":  nodeIdOnlyList15[j], "value": 1, connectingValue : 15}) 
        }
      }
    }
    setLinkList15(tempLinkList1)
  }, [nodeIdOnlyList15])
  useEffect(()=> {

    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList16.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList16[i] && nodeIdOnlyList16[j]) {
          tempLinkList1.push({"source": nodeIdOnlyList16[i], "target":  nodeIdOnlyList16[j], "value": 1, connectingValue : 16}) 
        }
      }
    }
    setLinkList16(tempLinkList1)
  }, [nodeList16])
  useEffect(()=> {
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList17.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList17[i] && nodeIdOnlyList17[j]  ) {
          tempLinkList1.push({"source": nodeIdOnlyList17[i], "target":  nodeIdOnlyList17[j], "value": 1, connectingValue : 17}) 
        }
      }
    }
    setLinkList17(tempLinkList1)

  }, [nodeList17])
  useEffect(()=> {
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList20.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList20[i] && nodeIdOnlyList20[j]  ) {
          tempLinkList1.push({"source": nodeIdOnlyList20[i], "target":  nodeIdOnlyList20[j], "value": 1, connectingValue : 20}) 
        }
      }
    }
    setLinkList20(tempLinkList1)

  }, [nodeList20])
  useEffect(()=> {
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList21.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList21[i] && nodeIdOnlyList21[j]  ) {
          tempLinkList1.push({"source": nodeIdOnlyList21[i], "target":  nodeIdOnlyList21[j], "value": 1, connectingValue : 21}) 
        }
      }
    }
    setLinkList21(tempLinkList1)

  }, [nodeList21])
  useEffect(()=> {
    var tempLinkList1 = [];
    // var mainList = nodeList1;
    for (var i=0;i< nodeIdOnlyList22.length; i++) {
      for (var j=0;j< 4; j++) {
        if (nodeIdOnlyList22[i] && nodeIdOnlyList22[j]  ) {
          tempLinkList1.push({"source": nodeIdOnlyList22[i], "target":  nodeIdOnlyList22[j], "value": 1, connectingValue : 22}) 
        }
      }
    }
    setLinkList21(tempLinkList1)

  }, [nodeList22])







  
  useEffect(()=> {

    if (selectedNode0 && selectedNode0 && selectedNode0.username) {
      const todoRef = firebase.database()
      .ref("behaviors/diary-checkins")
      .orderByChild("username")
      .equalTo( selectedNode0.username)

  
      todoRef.once("value", (snapshot) => {
        const todos = snapshot.val(); 
        const todoList = [];
        for (let id in todos) {
          // console.log("id", id)
          todoList.push({ id, name : id,  ...todos[id] });
        } 
        setSelectedNodes1(todoList);
        // console.log("todoList->", todoList);
      });
    }
  }, [selectedNode0])
   

  // console.log("selectedNode0", selectedNode0)
  // console.log("selectedNodes1", selectedNodes1)

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);

  const paintRing = useCallback((node, ctx) => {
    // add ring just for highlighted nodes
    ctx.beginPath();
    ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
    ctx.fillStyle = node === hoverNode ? 'red' : 'orange';
    ctx.fill();
  }, [hoverNode]);

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = node => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      node.neighbors.forEach(neighbor => highlightNodes.add(neighbor));
      node.links.forEach(link => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
  };

  const handleLinkHover = link => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      highlightLinks.add(link);
      highlightNodes.add(link.source);
      highlightNodes.add(link.target);
    }

    updateHighlight();
  };
  const fgRef = useRef();

  const handleFocus = useCallback(node => {
    // Aim at node from outside it
    const distance = 40;
    const distRatio = 1 + distance/Math.hypot(node.x, node.y, node.z);

    fgRef.current.cameraPosition(
      { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
      node, // lookAt ({ x, y, z })
      3000  // ms transition duration
    );
  }, [fgRef]);

  useEffect(()=> {
    if (textInput22 && textInput22 != "") {
      const nodes = [
        ...nodeList1,
        ...nodeList2,
        ...nodeList3,
        ...nodeList4,
        ...nodeList5,
        ...nodeList6,
        ...nodeList7,
        ...nodeList8,
        ...nodeList9,
        ...nodeList10,
        ...nodeList11,
        ...nodeList12,
        ...nodeList13,
        ...nodeList14,
        ...nodeList15,
        ...nodeList16,
        ...nodeList17,
        ...nodeList20,
        ...nodeList21,
        ...nodeList22,

      ]
      .reduce(function(acc, current) {
        if (acc.findIndex(({ id }) => id === current.id) === -1) {
          acc.push(current);
        }
        return acc;
      }, [])

      const index1 = nodes.findIndex((nodeItem, nodeIndex)=>  {
        if (nodeItem && nodeItem.email && nodeItem.email.indexOf(textInput22) > -1) {

          return nodeItem;
        }
      })
      // console.log("nodes", nodes)
      // console.log("index1", index1)
      if (index1 > -1 && nodes[index1]) {

        handleFocus(nodes[index1])
      }
    }
  }, [textInput22])

  
  return ( 
    <div className={`${compString1}wrapper`}>
      <Comp001TopNav
        openPopup1={boolOpenPopup1} setOpenPopup1={setBoolOpenPopup1}
        openPopup2={boolOpenPopup2} setOpenPopup2={setBoolOpenPopup2}
        openPopup3={boolOpenPopup3} setOpenPopup3={setBoolOpenPopup3}
        openPopup4={boolOpenPopup4} setOpenPopup4={setBoolOpenPopup4}
        openPopup5={boolOpenPopup5} setOpenPopup5={setBoolOpenPopup5}
        openPopup10={boolOpenPopup10} setOpenPopup10={setBoolOpenPopup10}
      />  
      <ForceGraph3D 
          ref={fgRef}
          nodeRelSize={NODE_R}
          autoPauseRedraw={false}
          backgroundColor={textColor1} 
          linkDirectionalParticles={"value"}
          linkDirectionalParticleSpeed={d => d.value * 0.011}
          nodeAutoColorBy={"memo"} 
          linkDirectionalParticleWidth={link => highlightLinks.has(link) ? 4 : 0}
          nodeCanvasObjectMode={node => highlightNodes.has(node) ? 'before' : undefined}
          linkWidth={link => highlightLinks.has(link) ? 5 : 1}
          nodeCanvasObject={paintRing}
          // onNodeHover={handleNodeHover}
          onLinkHover={handleLinkHover} 
          graphData={
            {
              // "nodes" : nodeList,
              // "links" : linkList,
              // "links" : linkList,
              links : 
              [
                ...linkList1,
                ...linkList2,
                ...linkList3,
                ...linkList4,
                ...linkList5,
                ...linkList6,
                ...linkList7,
                ...linkList8,
                ...linkList9,
                ...linkList10,
                ...linkList11,
                ...linkList12,
                ...linkList13,
                ...linkList14,
                ...linkList15,
                ...linkList16,
                ...linkList17,

                ...linkList20,
                ...linkList21,
                ...linkList22,
              ]
              .filter((filterItem)=> {
                // console.log("filterItem ",  filterItem )
                if ( filterItem.source != "undefined"   && filterItem.target != "undefined") {
                  return filterItem; 
                }
              })
              ,
              "nodes" : 
              [
                ...nodeList1,
                ...nodeList2,
                ...nodeList3,
                ...nodeList4,
                ...nodeList5,
                ...nodeList6,
                ...nodeList7,
                ...nodeList8,
                ...nodeList9,
                ...nodeList10,
                ...nodeList11,
                ...nodeList12,
                ...nodeList13,
                ...nodeList14,
                ...nodeList15,
                ...nodeList16,
                ...nodeList17,
                ...nodeList20,
                ...nodeList21,
                ...nodeList22,

              ]
              .reduce(function(acc, current) {
                if (acc.findIndex(({ id }) => id === current.id) === -1) {
                  acc.push(current);
                }
                return acc;
              }, []),
              // "links" : linkList,
              // "links" : linkList2
            }
          }
          nodeLabel="email"  
          linkVisibility={true} 
          nodeThreeObjectExtend={true}
          // nodeThreeObject={node=> {
          //   return node;
          // }}
          nodeThreeObject={node => {
            const nodeEl = document.createElement('div');
            nodeEl.textContent = "";
            nodeEl.style.color = node.color;
            nodeEl.className = 'node-label';
            return new CSS2DObject(nodeEl);
          }} 
          // nodeAutoColorBy="group"
          // linkThreeObjectExtend={false}
          // linkThreeObject={link => {
          //   // console.log("link", link)
          //   // extend link with text sprite
          //   // console.log("link.source", link.source)
          //   // console.log("link.source", link.source.tags)
          //   // console.log("link.target", link.target.tags)

          //   if (typeof link === 'object' && link &&link.connectingValue) {
          //     var returnLinkText1 = "";
          //     //9 , 4, 4, 3,
          //     if (link.connectingValue == 1) {
          //       returnLinkText1 = categoryOptions1[0].value;
          //     }
          //     if (link.connectingValue == 2) {
                
          //       returnLinkText1 = categoryOptions1[1].value;
          //     }
          //     if (link.connectingValue == 3) {
                
          //       returnLinkText1 = categoryOptions1[2].value;
          //     }
          //     if (link.connectingValue == 4) {
          //       returnLinkText1 = categoryOptions1[3].value;
                
          //     }
          //     if (link.connectingValue == 5) {
          //       returnLinkText1 = categoryOptions1[4].value;
                
          //     }
          //     if (link.connectingValue == 6) {
                
          //       returnLinkText1 = categoryOptions1[5].value;
          //     }
          //     if (link.connectingValue == 7) {
                
          //       returnLinkText1 = categoryOptions1[6].value;
          //     }
          //     if (link.connectingValue == 8) {
                
          //       returnLinkText1 = categoryOptions1[7].value;
          //     }
          //     if (link.connectingValue == 9) {
          //       returnLinkText1 = categoryOptions1[8].value;
                
          //     }
          //     if (link.connectingValue == 10) {
          //       returnLinkText1 = categoryOptions2[0].value;
                
          //     }

          //     if (link.connectingValue == 11) {
          //       returnLinkText1 = categoryOptions2[1].value;
                
          //     }
          //     if (link.connectingValue == 12) {
          //       returnLinkText1 = categoryOptions2[2].value;
                
                
          //     }
          //     if (link.connectingValue == 13) {
          //       returnLinkText1 = categoryOptions2[3].value;
                
          //     }
          //     if (link.connectingValue == 14) {
          //       returnLinkText1 = categoryOptions3[0].value;
                
          //     }
          //     if (link.connectingValue == 15) {
          //       returnLinkText1 = categoryOptions3[1].value;
                
          //     }
          //     if (link.connectingValue == 16) {
          //       returnLinkText1 = categoryOptions3[2].value;
                
          //     }
          //     if (link.connectingValue == 17) {
          //       returnLinkText1 = categoryOptions3[3].value;
                
          //     }
              
          //     if (link.connectingValue == 20) {
          //       returnLinkText1 = themeOptions1[0].value;
                
          //     }

          //     if (link.connectingValue == 21) {
          //       returnLinkText1 = themeOptions1[1].value;
                
          //     }
          //     if (link.connectingValue == 22) {
          //       returnLinkText1 = themeOptions1[2].value;
                
                
          //     }
          //     const sprite = new SpriteText(`${returnLinkText1}`);
          //     sprite.color = color1;
          //     // sprite.strokeColor = color1;
          //     // sprite.strokeWidth = 9;
          //     sprite.fontSize = 14;
          //     // sprite.backgroundColor = color1;
          //     sprite.textHeight = 2.25;
          //     // return sprite;
          //     return sprite 
          //   } else {
          //     if (typeof link === 'string' ) {
          //       const sprite = new SpriteText(`${link}`);
          //       sprite.color = color1;
          //       // sprite.strokeColor = color1;
          //       // sprite.strokeWidth = 9;
          //       sprite.fontSize = 14;
          //       // sprite.backgroundColor = color1;
          //       sprite.textHeight = 2.25;
          //       // return sprite;
          //       return sprite 
          //     }


          //   }
          // }}
          // linkPositionUpdate={(sprite, { start, end }) => {
          //   const middlePos = Object.assign(...['x', 'y', 'z'].map(c => ({
          //     [c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
          //   })));

          //   // console.log("sprite.position", sprite.position)

          //   // Position sprite
          //   return Object.assign(sprite.position, middlePos);
          // }} 
          onNodeClick={e=> {
            console.log("onNodeClick", e);

            setBoolOpenPopup11(true);
            setSelectedNode0(e)
            
          }} 
        /> 

      <Comp003BottomNav openPopup1={boolOpenPopup1} setOpenPopup1={setBoolOpenPopup1} />
      {
        boolOpenPopup1 && 
          <>
            <PopupFull1>  
              {/* <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
                <PopupBlur1 onClick={e => {
                  e.preventDefault();
                  e.stopPropagation(); 
                  setBoolOpenPopup1(false);
                  setBoolOpenPopup2(false);
                  setBoolOpenPopup3(false);
                  setBoolOpenPopup4(false);
                  setBoolOpenPopup5(false);
                  setBoolOpenPopup6(false);
                  setBoolOpenPopup10(false);

                }}></PopupBlur1>
                <Comp010LoginPopup/>
              </PopupFull1> 
            </>
      } 
      {
        boolOpenPopup2 && 
          <>
            <PopupFull1>  
              {/* <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
                <PopupBlur2 onClick={e => {
                  e.preventDefault();
                  e.stopPropagation(); 
                  setBoolOpenPopup1(false);
                  setBoolOpenPopup2(false);
                  setBoolOpenPopup3(false);
                  setBoolOpenPopup4(false);
                  setBoolOpenPopup5(false);
                  setBoolOpenPopup6(false);
                  setBoolOpenPopup10(false);

                }}></PopupBlur2>
                <Comp011SignupPopup/>
              </PopupFull1> 
            </>
      } 
      {
        boolOpenPopup3 && 
          <>
            <PopupFull1>  
              {/* <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
                <PopupBlur2 onClick={e => {
                  e.preventDefault();
                  e.stopPropagation(); 
                  setBoolOpenPopup1(false);
                  setBoolOpenPopup2(false);
                  setBoolOpenPopup3(false);
                  setBoolOpenPopup4(false);
                  setBoolOpenPopup5(false);
                  setBoolOpenPopup6(false);
                  setBoolOpenPopup10(false);

                }}></PopupBlur2>
                <Comp021ModifyPopup/>
              </PopupFull1> 
            </>
      } 
      {
        boolOpenPopup4 && 
          <>
            <PopupFull1>  
              {/* <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
                <PopupBlur2 onClick={e => {
                  e.preventDefault();
                  e.stopPropagation(); 
                  setBoolOpenPopup1(false);
                  setBoolOpenPopup2(false);
                  setBoolOpenPopup3(false);
                  setBoolOpenPopup4(false);
                  setBoolOpenPopup5(false);
                  setBoolOpenPopup6(false);
                  setBoolOpenPopup10(false);

                }}></PopupBlur2>
                {/* <Comp020SamplePopup/> */}
                {/* <iframe 
                className='iframe_wrapper'
                src="https://django-test-tensorflow-rsbs2b2ffy7dlozkjg8txp.streamlit.app/" 
                style={{ width : `100vw`, border : 'none'}}
                /> */}
              </PopupFull1> 
            </>
      } 
      {
        boolOpenPopup5 && 
          <>
            <PopupFull1>  
              {/* <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
                <PopupBlur2 onClick={e => {
                  e.preventDefault();
                  e.stopPropagation(); 
                  setBoolOpenPopup1(false);
                  setBoolOpenPopup2(false);
                  setBoolOpenPopup3(false);
                  setBoolOpenPopup4(false);
                  setBoolOpenPopup5(false);
                  setBoolOpenPopup6(false);
                  setBoolOpenPopup10(false);

                }}></PopupBlur2>
                {/* <Comp020SamplePopup/> */}
                <div className='searchPopupWrapper'>

                  <img className='toplogo' src={`/static/icon_3logo.png`} alt=''/>
                  <div className='title'>
                  Search
                  </div>
                  <input 
                    className='searchPopInput' 
                    value={textInput22} 
                    onChange={e=> {setTextInput22(e.target.value)}} 
                    placeholder='Search' style={{ }} 
                    />
                  <div className='title'>
                  Media
                  </div>
                  <Select
                  defaultValue={categoryOptions1[1]}
                  label="Select Tags"
                  // options={colourOptions}
                  styles={colourStyles} 
                  isMulti
                  value={selectedOption}
                  onChange={handleChange}
                  options={groupedOptions}

                  />  
                {/* <Comp999SelectArea001/> */}
                </div>


              </PopupFull1> 
            </>
      } 
      {
        boolOpenPopup10 && 
          <>
            <PopupFull1>  
              {/* <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
                <PopupBlur2 onClick={e => {
                  e.preventDefault();
                  e.stopPropagation(); 
                  setBoolOpenPopup1(false);
                  setBoolOpenPopup2(false);
                  setBoolOpenPopup3(false);
                  setBoolOpenPopup4(false);
                  setBoolOpenPopup5(false);
                  setBoolOpenPopup6(false);
                  setBoolOpenPopup10(false);

                }}></PopupBlur2>
                <Comp030CreatePostPopup/>
              </PopupFull1> 
            </>
      } 
      {
        boolOpenPopup11 && 
          <>
            <PopupFull1>  
              {/* <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
                <PopupBlur2 onClick={e => {
                  e.preventDefault();
                  e.stopPropagation(); 
                  setBoolOpenPopup1(false);
                  setBoolOpenPopup2(false);
                  setBoolOpenPopup3(false);
                  setBoolOpenPopup4(false);
                  setBoolOpenPopup5(false);
                  setBoolOpenPopup6(false);
                  setBoolOpenPopup10(false);
                  setBoolOpenPopup11(false);

                }}></PopupBlur2>
                <Comp040ViewPostPopup
                selectedNode0={selectedNode0}
                selectedNodes1={selectedNodes1}
                setSelectedNode0={setSelectedNode0}
                setSelectedNodes1={setSelectedNodes1}
                onClose={() => {

                  setBoolOpenPopup1(false);
                  setBoolOpenPopup2(false);
                  setBoolOpenPopup3(false);
                  setBoolOpenPopup4(false);
                  setBoolOpenPopup5(false);
                  setBoolOpenPopup6(false);
                  setBoolOpenPopup10(false);
                  setBoolOpenPopup11(false);
                  setSelectedNode0();
                  setSelectedNodes1([])
                }
                }
                />
              </PopupFull1> 
            </>
      } 
      {
        boolOpenPopup12 && 
          <>
            <PopupFull1>  
              {/* <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
                <PopupBlur2 onClick={e => {
                  e.preventDefault();
                  e.stopPropagation(); 
                  setBoolOpenPopup1(false);
                  setBoolOpenPopup2(false);
                  setBoolOpenPopup3(false);
                  setBoolOpenPopup4(false);
                  setBoolOpenPopup5(false);
                  setBoolOpenPopup6(false);
                  setBoolOpenPopup10(false);
                  setBoolOpenPopup11(false);

                }}></PopupBlur2> 
                <div style={{}} >
{/* 
                  <iframe 
                  src={`https://django-test-tensorflow-rsbs2b2ffy7dlozkjg8txp.streamlit.app`} 
                  style={{ }}
                  /> */}

                </div>
              </PopupFull1> 
            </>
      } 
      { 
      <div className="  fab_button2" onClick={e=> {
        e.preventDefault(); e.stopPropagation();
        if (textColor1 == "#fff" ) {
          setTextColor1("#000")
        } else {
          setTextColor1("#fff")
        }
      }}>
      <div class="dropup">
      <button class="dropbtn"> 
      Background Color
      </button>
      <div class="dropup-content" >
          <a >
            Background
          </a> 
          
      </div>
      </div>
      </div>
      }
      { 
      <div className="  fab_button3" onClick={e=> {
        e.preventDefault(); e.stopPropagation();
        if (textColor1 == "#fff" ) {
          setTextColor1("#000")
        } else {
          setTextColor1("#fff")
        }
      }}>
      <div class="dropup">
      <button class="dropbtn"> 
        Searching
      </button>
      <div class="dropup-content" >
          <a >
            Background
          </a> 
          
      </div>
      </div>
      </div>
      }
      { 
      
      textLoggedIn && textLoggedIn != "" && 
      <div className="  fab_button1" 
      onClick={e=> {
        e.preventDefault(); 
        e.stopPropagation();  
        setBoolOpenPopup1(false);
        setBoolOpenPopup2(false);
        setBoolOpenPopup3(false);
        setBoolOpenPopup4(false);
        setBoolOpenPopup5(false);
        setBoolOpenPopup6(false);
        setBoolOpenPopup10(true);
      }}>
      <div class="dropup">
      <button class="dropbtn"> 
        Create Post
      </button>
      <div class="dropup-content" >
          <a >
          Post
          </a> 
          
      </div>
      </div>
      </div>
      }
      {/* { 
      <div className="  fab_button0" onClick={e=> {
        e.preventDefault(); 
        e.stopPropagation();
        e.preventDefault(); 
        e.stopPropagation();  
        setBoolOpenPopup1(false);
        setBoolOpenPopup2(false);
        setBoolOpenPopup3(false);
        setBoolOpenPopup4(false);
        setBoolOpenPopup5(false);
        setBoolOpenPopup6(false);
        setBoolOpenPopup10(false);
        setBoolOpenPopup11(false);
        // setBoolOpenPopup12(true);
        window.open(`https://django-test-tensorflow-rsbs2b2ffy7dlozkjg8txp.streamlit.app`)

      }}>
      <div class="dropup">
      <button class="dropbtn"> 
        AI
      </button>
      <div class="dropup-content" >
          <a >
          AI
          </a>  
      </div>
      </div>
      </div>
      } */}
      <style>{`
            .${compString1}wrapper {
                width : 100vw;
                height : 100vh;
                overflow : scroll;

                display: flex;
                flex-direction : column; 
            } 
            .link{
                stroke: ${color1};
                stroke-width: 2px;
            }

            .fab_button0 {
                width : 60px;
                height : 60px;
                border-radius : 50%;
                
                position: fixed;
                bottom : 20px;
                right: 28px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                background: #fff;
            
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 9999;
            }
            .fab_button0 img {
                width : 24px;
                height : 24px;
                object-fit : contain;
            }
            .fab_button2 {
                width : 60px;
                height : 60px;
                border-radius : 50%;
                
                position: fixed;
                bottom :180px;
                right: 28px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                background: #fff;
            
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 9999;
            }
            .fab_button2 img {
                width : 24px;
                height : 24px;
                object-fit : contain;
            }
            .fab_button1 {
                width : 60px;
                height : 60px;
                border-radius : 50%;
                
                position: fixed;
                bottom : 100px;
                right: 28px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                background: #fff;
            
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 9999;
            }
            .fab_button3 img {
                width : 24px;
                height : 24px;
                object-fit : contain;
            }
            
            .fab_button3 {
                width : 60px;
                height : 60px;
                border-radius : 50%;
                
                position: fixed;
                bottom :100px;
                right: 28px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                background: #fff;
            
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 9999;
            }
            .fab_button2 img {
                width : 24px;
                height : 24px;
                object-fit : contain;
            }
.dropup-content {
  display: none;
  position: absolute; 
  min-width: 160px;
  bottom: 50px;
  right : 20px;
  z-index: 1;
}

.dropup-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color : ${color1};
  color : #fff;

}
.dropbtn {
  color : #000;
}
.dropup-content a:hover { background-color : #000; }
.dropup-content a:active { background-color : #000; }

.dropup:hover .dropup-content {
  display: block;
}

.dropup:active .dropup-content {
  display: block;
}

        `}</style>

<style>{`
                  .searchPopupWrapper {
                    width : 600px;
                    max-width : calc(100vw - 40px);
                    height : 400px;

                    background-color : #fff;
                    z-index: 9999;
                    position: fixed;
                    left: 50%;
                    top: 50%;
                    transform : translate(-50%, -50%);
                    border-radius: 40px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    overflow : visible;
                  }

                  .searchPopupWrapper > .toplogo {
 
                    width : 80px;
                    height : 40px;
                    object-fit : contain;

                  }

                  .searchPopupWrapper > .title {

                    color : ${color1};
                    font-size: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  }


                  .searchPopupWrapper > div {
                    width : 400px;
                    margin-bottom: 20px; 
                  }
<<<<<<< HEAD
                  
                  .iframe_wrapper {
                    width : calc(100vw - 40px);
                    height : calc(100vh - 40px);
                    background : #fff;
                    z-index: 9999;
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 20px;
                  }
=======
                
                  .searchPopInput {
                    border : 1px solid #aeaeae;
                    height : 30px;
                    width : 300px;
                    border-radius: 20px;

                    margin-top: 0px;
                    margin-bottom : 20px;
                    padding-left: 20px;
                    padding-right : 20px;
                  }
                  @font-face {
                    font-family: 'Pixer';
                    src: url('/static/Pixer-Regular.woff2') format('woff2'),
                        url('/static/Pixer-Regular.woff') format('woff');
                    font-weight: normal;
                    font-style: normal;
                    font-display: swap;
                }

                * {

                  font-family: 'Pixer';
                }
                

                
>>>>>>> 5fe1fad5cf25c92e3a5986a9e7da8bebbda225ba
                
                `}</style>
    </div>
  );
}

export default App;
