import React, { Component, useEffect, useState, useRef} from 'react'; 
import styled from "styled-components"
import Firebase from "firebase";
import firebase from "../firebase";
import fireDb from "../firebase";
import moment from 'moment'

import Select from 'react-select'

import QuillWrapper from "react-quill";
import 'react-quill/dist/quill.snow.css'; 
import imageCompression from "browser-image-compression";

const modules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    // ['bold', 'italic', 'underline', 'strike', 'blockquote', 'image', 'camera'],
    // [
    //   { list: 'ordered' },
    //   { list: 'bullet' },
    //   { indent: '-1' },
    //   { indent: '+1' },
    // ],
    // // ['link', 'image', 'video'],
    // // ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  // 'link',
  'image',
  // 'camera',
  // 'video',
]



const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
        outerWidth : "600px",
        innerWidth : "600px",
        
        
      };
    }
  };
  
  
  const categoryOptions1 = [
    
    { value: "디지털그래픽 CGI(2D/3D)	computer-generated-imagery-2d-3d", label: "디지털그래픽 CGI(2D/3D)	computer-generated-imagery-2d-3d", color : "#AE47FF", },
    { value: "웹툰/만화 Webtoon/Comics	webtoon-comics", label: "웹툰/만화 Webtoon/Comics	 ", color : "#AE47FF", },
    { value: "일러스트 Illustration	illustration", label: "일러스트 Illustration	 ", color : "#AE47FF", },
    { value: "캐릭터디자인 Character Design	character-design", label: "캐릭터디자인 Character Design	 ", color : "#AE47FF", },
    { value: "캘리그래피 Calligraphy	calligraphy", label: "캘리그래피 Calligraphy	 ", color : "#AE47FF", },
    { value: "타투디자인 Tatoo Design	tatoo-design", label: "타투디자인 Tatoo Design	 ", color : "#AE47FF", },
    { value: "패션일러스트 Fashion Illustration	fashion-illustration", label: "패션일러스트 Fashion Illustration ", color : "#AE47FF", },
    { value: "패턴디자인 Pattern Design	pattern-design", label: "패턴디자인 Pattern Design ", color : "#AE47FF", },
    { value: "피규어디자인 Art Toy	art-toy", label: "피규어디자인 Art Toy ", color : "#AE47FF", },
    
    
    ];
  
    const categoryOptions2 = [
  
        { value : "사진 Photography	photography", label :  "사진 Photography ", color : "#AE47FF", },
        { value : "조각 Sculpture	sculpture", label :  "조각 Sculpture ", color : "#AE47FF", },
        { value : "판화 Print	print", label :  "판화 Print ", color : "#AE47FF", },
        { value : "회화 Painting	painting", label :  "회화 Painting ", color : "#AE47FF", },
      
      
      ];
  
      
  
      const categoryOptions3 = [
        { value: "모션그래픽 Motion Graphic	motiongraphic", label: "모션그래픽 Motion Graphic", color : "#AE47FF",},
        { value: "미디어아트 Media Art	media-art", label: "미디어아트 Media Art ", color : "#AE47FF",},
        { value: "애니메이션 Animation	animation", label: "애니메이션 Animation ", color : "#AE47FF",},
        { value: "영화 Film Production	Film Production", label: "영화 Film Production ", color : "#AE47FF",},
        
        ]; 
        
  const flavourOptions = [
    { value: "vanilla", label: "Vanilla", rating: "safe" },
    { value: "chocolate", label: "Chocolate", rating: "good" },
    { value: "strawberry", label: "Strawberry", rating: "wild" }
    
  ];
  
  
  
  const groupedOptions = [
    {
      label: "그래픽아트 Graphic Art	Graphic Art",
      options: categoryOptions1
    }, 
    {
      label: "순수미술 Fine Art	fine-art",
      options: categoryOptions2
    }, 
    {
      label: "영상 Video Graphic	videoart",
      options: categoryOptions3
    },  
  ];
 
const color1 = `#8A2BE2`;
const color2 = `#9B1CFF`;
const color3 = `#2DFF0B`;
const compString1 = `popup2_`
const staticString1 = `/static/logo_pictor000.png`
const staticString2 = `/static/icon_sort.png`
const staticString3 = `/static/icon_search.png`
const staticString4 = `/static/icon_wow.png`
const staticString5 = '/static/left-arrow001.png';
const arr1 = ['Account', 'Name', 'beginning', 'habitat', 'Theme?', 'notes', 'Media', 'Artwork']

function Comp() {

    const [textUploadId1, setTextUploadId1] = useState(moment().format("YYYYMMDDHHmmssms"))

    const [intStep0, setIntStep0] = useState(4);
    const [intStep1, setIntStep1] = useState(1);
    const [text1, setText1] = useState("");
    const [text2, setText2] = useState("");
    const [text3, setText3] = useState("");
    const [text4, setText4] = useState("");
    const [text5, setText5] = useState("");
    const [text6, setText6] = useState("");
    const [text7, setText7] = useState("");
    const [text8, setText8] = useState("");

    const [selectedOption, setSelectedOption] = useState();
    const [textValue1, setTextValue1] = useState([]);
    const [textValue2, setTextValue2] = useState([]);
  
    const [quillValue1, setQuillValue1] = useState();
  

    const textAreaRef1 = useRef(null);
    const textAreaRef2 = useRef(null);
    const imageRef0 = useRef();
    const imageRef1 = useRef();
    const imageRef2 = useRef();
    const imageRef3 = useRef();
    const imageRef4 = useRef();
    const imageRef5 = useRef();
    const imageRef6 = useRef();
    const imageRef7 = useRef();
    const imageRef8 = useRef();
    const imageRef9 = useRef();
    const imageRef10 = useRef();
  
    const [imageUpload0, setImageUpload0] = useState('');
    const [imageUpload1, setImageUpload1] = useState('');
    const [imageUpload2, setImageUpload2] = useState('');
    const [imageUpload3, setImageUpload3] = useState('');
    const [imageUpload4, setImageUpload4] = useState('');
    const [imageUpload5, setImageUpload5] = useState('');
    const [imageUpload6, setImageUpload6] = useState('');
    const [imageUpload7, setImageUpload7] = useState('');
    const [imageUpload8, setImageUpload8] = useState('');
    const [imageUpload9, setImageUpload9] = useState('');
    const [imageUpload10, setImageUpload10] = useState('');
    
    const [imageLink0, setImageLink0] = useState('');
    const [imageLink1, setImageLink1] = useState('');
    const [imageLink2, setImageLink2] = useState('');
    const [imageLink3, setImageLink3] = useState('');
    const [imageLink4, setImageLink4] = useState('');
    const [imageLink5, setImageLink5] = useState('');
    const [imageLink6, setImageLink6] = useState('');
    const [imageLink7, setImageLink7] = useState('');
    const [imageLink8, setImageLink8] = useState('');
    const [imageLink9, setImageLink9] = useState('');
    const [imageLink10, setImageLink10] = useState('');
    const [intUploadedNumber1, setIntUploadedNumber1] = useState(0);
  

    function handleChange(selectedOption) {
      console.log("selectedOption", selectedOption);
      setSelectedOption(selectedOption) ;
  };

  const actionImgCompress = async (paramIndex, fileSrc, type) => {
    console.log("압축 시작");

    const options = {
      maxSizeMB: 0.10,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      // 압축 결과
      const compressedFile = await imageCompression(fileSrc, options);
      upload(paramIndex, compressedFile)

    } catch (error) {
      console.log(error);
    }
  };

    function handleSignUp() { 
      Firebase.database().ref()
            .child("behaviors/diary-checkins")
            // .orderByChild("username")
            // .equalTo(text1)
            .once("value", (snapshot) => {

                  console.log("handleSignUp - 1", snapshot)
                if (snapshot.val()) {
                    const data = snapshot.val();
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) {

                    //   setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>해당 아이디 사용중입니다.</div>)
                        // Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
                        // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)
                        if (data[keyName].status == "YES") {
                            
                        } else {
                            // setBlockSystem(true);
                        }
                    } else {
                        //조건 분기 필요

                    }

                }  else {
                  
                  console.log("handleSignUp - 2", )
                  fireDb.database().ref().child("accounts").push(
                    {
                    //   check_agree1 : boolCheck1,
                    //   check_agree2 : boolCheck2,
                    //   check_agree3 : boolCheck3,
                    //   check_agree4 : boolCheck4,
                    //   nation : textInput1,  //"in" 내국인, "out" 외국인
                      // 지금수정중
                    //   parent_name : textInput2,
                    //   contact_number : textInput3,
                    //   contact_secret : textInput4,
                    //   profile_image : imageLink,
                    username : text1,
                    nickname : text1,
                    password: text2,
                    firstname: text4,
                    lastname: text5,
                    nation: text7,
                    memo : text8,
                    email : text1,
                    //   gender : textInput16, // "male", "female"
                    //   usertype : textInput17,
                    //   birthdate : textInput18,
                    //   grade : textInput19, 
                    uuid : moment().format("YYYYMMDDHHmmssSSS"),
                    random_id16 : makeId(16),
                    random_id8 : makeId(8),
                    tag1 : selectedOption && selectedOption[0]  && selectedOption[0].value ? selectedOption[0].value : "",
                    tags :selectedOption && selectedOption.length > 0 ? selectedOption.filter((filterItem)=> {
                        return filterItem
                    }).map((filterItem)=> {
                        return filterItem.value
                    }).join("-") : "" 
                    //   restoration_code : textInput21,

                      // account : textInput1, 
                      // email : textInput1, 
                      // password : textInput2,
                    }
                    , (err) => {
                    if (err) {
                      // toast.error(err);
                      console.log(err);
                    } else {
                        alert("가입되셨습니다. ")
                        // router.reload();
                        window.location.href = "/"
                      // toast.success("Signup  Successfully");

                    //   setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}> 회원가입되었습니다.</div>)

                      // setTimeout(()=> {
                      //     const pageCodeGo1 =  "page000/0/login";
                      //     setScreenMode1(pageCodeGo1)
                      // }, 1500)

                      // alert("")
                      
                    }
                  });
                }
            });
    
    }

  
    function handleCreate() {


        console.log("handleCreate - 0", )
  
        const authStoreKeyName = localStorage.getItem("authStoreKeyName");
        const authStoreUserName = localStorage.getItem("authStoreUserName");
        
        const authStore1 = localStorage.getItem("authStore")
   
        
        fireDb.database().ref().child("behaviors/diary-checkins/" + textUploadId1).update(
          {
            title : text1,
            contents : quillValue1 ? quillValue1 : '',
            pre_contents: quillValue1 ? quillValue1.replace(/<[^>]+>/g, '') : '',
            username : authStoreUserName ? authStoreUserName : "", 
            created_at : moment().format("YYYY-MM-DD HH:mm:ss"),
            created_day : moment().format("YYYY-MM-DD"),
            created_time : moment().format('hh:mm A'),
  
            imageLink1 : imageLink1, 
            imageLink2 : imageLink2, 
            imageLink3 : imageLink3, 
            imageLink4 : imageLink4, 
            imageLink5 : imageLink5, 
            imageLink6 : imageLink6, 
            imageLink7 : imageLink7, 
            imageLink8 : imageLink8, 
            imageLink9 : imageLink9, 
            imageLink10 : imageLink10, 
            color : "#AE47FF",
            tag1 : selectedOption && selectedOption[0]  && selectedOption[0].value ? selectedOption[0].value : "",
            tags : selectedOption && selectedOption.length > 0 ? selectedOption.filter((filterItem)=> {
                return filterItem
            }).map((filterItem)=> {
                return filterItem.value
            }).join("-") : "" 
          }
          , (err) => {
          if (err) {
            // toast.error(err);
            console.log(err);
          } else {
            // toast.success("Signup  Successfully");
  
            // setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}> 회원가입되었습니다.</div>)
  
            setTimeout(()=> { 
                setText1("")
                setTextValue1("")
                setTextValue2("") 
                setQuillValue1();
                setImageLink0('')
                setImageLink1('')
                setImageLink2('')
                setImageLink3('')
                setImageLink4('')
                setImageLink5('')
                setImageLink6('')
                setImageLink7('')
                setImageLink8('')
                setImageLink9('')
                setImageLink10('')
                window.location.href = "/"
            }, 300)
  
            // alert("")
            
          }
        });
  
      
      }

    const upload = (paramIndex, paramUpload)=>{
   
  
      var returnName1 = `${paramUpload.name}_${moment().format("YYYYMMDD_HHmmss")}`;
      if(paramUpload == null)
        return;
        const uploadTask =  fireDb.storage().ref(`/images/${returnName1}`).put(paramUpload)
        uploadTask
        .on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done');
          // switch (snapshot.state) {
          //   case 'paused':
          //     console.log('Upload is paused');
          //     break;
          //   case 'running':
          //     console.log('Upload is running');
          //     break;
          // }
        }, 
        (error) => {
          // Handle unsuccessful uploads
        }, 
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          fireDb.storage().ref('images').child(returnName1).getDownloadURL()
           .then(fireBaseUrl => {
            //  setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
            console.log("fireBaseUrl", fireBaseUrl) 
  
            if (paramIndex === 0) {
              setImageLink0(fireBaseUrl);
              // 
              Firebase.database().ref()
              .child("behaviors/diary-checkins/" + textUploadId1).update({"avatar" : fireBaseUrl});
            } 
            if (paramIndex === 1) {
              setImageLink1(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
            }
            if (paramIndex === 2) {
              setImageLink2(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
            }
            if (paramIndex === 3) {
              setImageLink3(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
            }
            if (paramIndex === 4) {
              setImageLink4(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
            }
            if (paramIndex === 5) {
              setImageLink5(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
            }
            if (paramIndex === 6) {
              setImageLink6(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
            }
            if (paramIndex === 7) {
              setImageLink7(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
            }
            if (paramIndex === 8) {
              setImageLink8(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
            }
            if (paramIndex === 9) {
              setImageLink9(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
            }
            if (paramIndex === 10) {
              setImageLink10(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
            }
           })
        }
      );
  
    }

    function makeId(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
  


  useEffect(()=> {
    const index1 = 0;
    actionImgCompress(0, imageUpload0);
    // checkImageNumber();
  },[
    imageUpload0,
  ])

  useEffect(()=> {
    const index1 = 1;
    actionImgCompress(index1, imageUpload1);
    checkImageNumber();
  },[
    imageUpload1,
  ])
  useEffect(()=> {
    const index1 = 2;
    actionImgCompress(index1, imageUpload2);
    checkImageNumber();
  },[
    imageUpload2,
  ])
  useEffect(()=> {
    const index1 = 3;
    actionImgCompress(index1, imageUpload3);
    checkImageNumber();
  },[
    imageUpload3,
  ])

  useEffect(()=> {
    const index1 = 4;
    actionImgCompress(index1, imageUpload4);
    checkImageNumber();
  },[
    imageUpload4,
  ])
  useEffect(()=> {
    const index1 = 5;
    actionImgCompress(index1, imageUpload5);
    checkImageNumber();
  },[
    imageUpload5,
  ])
  useEffect(()=> {
    const index1 = 6;
    actionImgCompress(index1, imageUpload6);
    checkImageNumber();
  },[
    imageUpload6,
  ])
  useEffect(()=> {
    const index1 = 7;
    actionImgCompress(index1, imageUpload7);
    checkImageNumber();
  },[
    imageUpload7,
  ])

  useEffect(()=> {
    const index1 = 8;
    actionImgCompress(index1, imageUpload8);
    checkImageNumber();
  },[
    imageUpload8,
  ])
  useEffect(()=> {
    const index1 = 9;
    actionImgCompress(index1, imageUpload9);
    checkImageNumber();
  },[
    imageUpload9,
  ])
  useEffect(()=> {
    const index1 = 10;
    actionImgCompress(index1, imageUpload10);
    checkImageNumber();
  },[imageUpload10,])

  function checkImageNumber() {
    var checkIndex1 = 0;
    if (imageUpload1) {
      checkIndex1 ++;
    }
    if (imageUpload2) {
      checkIndex1 ++;
    }
    if (imageUpload3) {
      checkIndex1 ++;
    }
    if (imageUpload4) {
      checkIndex1 ++;
    }
    if (imageUpload5) {
      checkIndex1 ++;
    }
    if (imageUpload6) {
      checkIndex1 ++;
    }
    if (imageUpload7) {
      checkIndex1 ++;
    }
    if (imageUpload8) {
      checkIndex1 ++;
    }
    if (imageUpload9) {
      checkIndex1 ++;
    }
    if (imageUpload10) {
      checkIndex1 ++;
    }
    setIntUploadedNumber1(checkIndex1)

  }


  const resizeTextArea = () => {
    if (textAreaRef1 && textAreaRef1.current && textAreaRef1.current.style) {

      textAreaRef1.current.style.height = "auto";
      textAreaRef1.current.style.height = textAreaRef1.current.scrollHeight + "px";
  
    }
    if (textAreaRef2 && textAreaRef2.current && textAreaRef2.current.style) {

      textAreaRef2.current.style.height = "auto";
      textAreaRef2.current.style.height = textAreaRef2.current.scrollHeight + "px";
    }
  };


    return ( 
        <div className={`${compString1}wrapper`}> 
            <div className={`${compString1}inner_wrapper`}> 
                
                <div className={`cursor_pointer ${compString1}button0  `}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if ( intStep1 > 0) {
                            setIntStep1(intStep1 - 1)
                        }
                    }}
                >
                    {
                        intStep1 != 1 &&
                        <img src={staticString5} alt='' style={{ width  : '20px', height : '20px', marginRight : '4px'}}/>
                    }
                    
                    Create Post  {intStep1} / {intStep0}
                </div>
            {
                intStep1 == 1 && <>
                    <div className={`${compString1}input01`}>
                        <div className={`${compString1}input01_input_paddingleft`} ></div>
                        <input className={`${compString1}input01_input`} value={text1} onChange={e=> {setText1(e.target.value)}} placeholder='TITLE'></input>
                    </div> 
                </>
                } 
                {
                intStep1 == 2 && <> 
                <QuillWrapper
                  style={{
                    maxWidth : `calc(100vw - 80px)`,
                    width :`calc(100% - 80px)`,
                    minHeight: `400px`,
                    maxHeight: `calc(100% - 200px)`,
                    marginBottom : `80px`,
                    color : `#000`,
                  }}
                  theme={'snow'}
                  id={'description'}
                  placeholder={'추가설명을 입력해주세요'}
                  value={quillValue1}
                  modules={modules}
                  formats={formats}
                  onChange={(event) => setQuillValue1(event)}
                  
                  onFocus={e=> {
                    //   setSttType("text3")
                      // setSttAction("start")
                  }}
                  onBlur={e=> {

                      // setSttType("")
                      // setSttAction("stop")
                  }}
                /> 
                </>
                } 
                {
                    intStep1 == 3 &&
                    <>

<div className="sectionLabel0 sectionLabel1">
                      {`업로드 이미지 (선택)`}
                  </div>
                  <div className="imagePlusRow0 imagePlusRow1">
                      
                    <div className="row0 row1">
      
      <input ref={imageRef0} 
              type="file" 
              onChange={(e)=>{
                
                setImageUpload0(e.target.files[0])
              }} 
              // accept="image/*"
               accept=".gif, .jpg, .png"
             // accept=".png"
              style={{display : 'none'}} alt="" />
              <button onClick={upload} style={{display : 'none'}}>Upload</button>
      
                  <input ref={imageRef1} 
                          type="file" 
                          onChange={(e)=>{setImageUpload1(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          <input ref={imageRef2} 
                          type="file" 
                          onChange={(e)=>{setImageUpload2(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef3} 
                          type="file" 
                          onChange={(e)=>{setImageUpload3(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef4} 
                          type="file" 
                          onChange={(e)=>{setImageUpload4(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef4} 
                          type="file" 
                          onChange={(e)=>{setImageUpload4(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
      
                          <input ref={imageRef5} 
                          type="file" 
                          onChange={(e)=>{setImageUpload5(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
      
                          <input ref={imageRef6} 
                          type="file" 
                          onChange={(e)=>{setImageUpload6(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef7} 
                          type="file" 
                          onChange={(e)=>{setImageUpload7(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef8} 
                          type="file" 
                          onChange={(e)=>{setImageUpload8(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef9} 
                          type="file" 
                          onChange={(e)=>{setImageUpload9(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef10} 
                          type="file" 
                          onChange={(e)=>{setImageUpload10(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
      
                  <div className="radius001_image_row_wrapper">
      
                  <div className="radius001_image_wrapper" 
                          style={{ 
                            background: `rgba(33, 37, 41, 0.7)`,
                            border : `none` ,
                          }}
                        >
                          {
                            // imageUpload1 == '' &&
                            <>
                            <div className="radius001_0" 
                              onClick={e=> {
                              e.preventDefault();
                              e.stopPropagation();
                              if (imageUpload1) {
                              } else {
                                imageRef1.current.click();
                                return;
                              }
                              if (imageUpload2) {
                              } else {
                                imageRef2.current.click();
                                return;
                              }
                              if (imageUpload3) {
                              } else {
                                imageRef3.current.click();
                                return;
                              }
                              if (imageUpload4) {
                              } else {
                                imageRef4.current.click();
                                return;
                              }
                              if (imageUpload5) {
                              } else {
                                imageRef5.current.click();
                                return;
                              }
                              if (imageUpload6) {
                              } else {
                                imageRef6.current.click();
                                return;
                              }
                              if (imageUpload7) {
                              } else {
                                imageRef7.current.click();
                                return;
                              }
                              if (imageUpload8) {
                              } else {
                                imageRef8.current.click();
                                return;
                              }
                              if (imageUpload9) {
                              } else {
                                imageRef9.current.click();
                                return;
                              }
                              if (imageUpload10) {
                              } else {
                                imageRef10.current.click();
                                return;
                              }
      
                            }}> 
                              <div className="radius001_desc0 cursor_pointer">사진 추가</div>
                            </div>
                            </>
                          }
                          <div>
                          </div>
                    </div>
      
                    {
                      imageLink1 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef1.current.click();
                      }}
                    >
                      {
                        imageLink1 !== '' &&
                        <>
                        <img className="full_image" src={imageLink1} alt=""/>
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                      imageLink2 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef2.current.click();
                      }}
                    >
                      {
                        imageLink2 !== '' &&
                        <>
                        <img className="full_image" src={imageLink2} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                      imageLink3 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef3.current.click();
                      }}
                    >
                      {
                        imageLink3 !== '' &&
                        <>
                        <img className="full_image" src={imageLink3} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                      imageLink4 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef4.current.click();
                      }}
                    >
                      {
                        imageLink4 !== '' &&
                        <>
                        <img className="full_image" src={imageLink4} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
      
      
      {
                      imageLink5 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef5.current.click();
                      }}
                    >
                      {
                        imageLink5 !== '' &&
                        <>
                        <img className="full_image" src={imageLink5} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                                    imageUpload6 !== '' && 
                    
                                    <div className="radius001_image_wrapper" 
                                    style={{ 
                                      background:  `transparent`,
                                      border : `1px solid #aeaeae`,
                                    }}
                                    onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      imageRef6.current.click();
                                    }}
                                  >
                                    {
                                      imageUpload6 !== '' &&
                                      <>
                                      <img className="full_image" src={imageLink6} alt="" />
                                      </>
                                    }
                                    <div>
                                    </div>
                              </div>
                                  }
                                  {
                              imageUpload7 !== '' && 
              
                              <div className="radius001_image_wrapper" 
                              style={{ 
                                background:  `transparent`,
                                border : `1px solid #aeaeae`,
                              }}
                              onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                imageRef7.current.click();
                              }}
                            >
                              {
                                imageUpload7 !== '' &&
                                <>
                                <img className="full_image" src={imageLink7} alt="" />
                                </>
                              }
                              <div>
                              </div>
                        </div>
                            }
                            {
                        imageUpload8 !== '' && 
        
                        <div className="radius001_image_wrapper" 
                        style={{ 
                          background:  `transparent`,
                          border : `1px solid #aeaeae`,
                        }}
                        onClick={e=> {
                          e.preventDefault();
                          e.stopPropagation();
                          imageRef8.current.click();
                        }}
                      >
                        {
                          imageUpload8 !== '' &&
                          <>
                          <img className="full_image" src={imageLink8} alt="" />
                          </>
                        }
                        <div>
                        </div>
                  </div>
                      }
                      {
                  imageUpload9 !== '' && 
      
                  <div className="radius001_image_wrapper" 
                  style={{ 
                    background:  `transparent`,
                    border : `1px solid #aeaeae`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    imageRef9.current.click();
                  }}
                >
                  {
                    imageUpload9 !== '' &&
                    <>
                    <img className="full_image" src={imageLink9} alt="" />
                    </>
                  }
                  <div>
                  </div>
            </div>
                }
      
      {
                  imageUpload10 !== '' && 
      
                  <div className="radius001_image_wrapper" 
                  style={{ 
                    background:  `transparent`,
                    border : `1px solid #aeaeae`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    imageRef9.current.click();
                  }}
                >
                  {
                    imageUpload10 !== '' &&
                    <>
                    <img className="full_image" src={imageLink10} alt="" />
                    </>
                  }
                  <div>
                  </div>
            </div>
                }
                      </div>
                      </div>
                  </div>
                    </>
                    
                }
                {
                    intStep1 == 4 &&
                    <>
                        
                <div className='searchPopupWrapper'>

                <div className={`cursor_pointer ${compString1}button0  `}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if ( intStep1 > 0) {
                            setIntStep1(intStep1 - 1)
                        }
                    }}
                >
                    {
                        intStep1 != 1 &&
                        <img src={staticString5} alt='' style={{ width  : '20px', height : '20px', marginRight : '4px'}}/>
                    }
                    
                    Create Post  {intStep1} / {intStep0}
                </div>
                    <img className='toplogo' src={`/static/icon_3logo.png`} alt=''/>
                    <div className='title'>
                    Media
                    </div>
                    <Select
                    defaultValue={categoryOptions1[1]}
                    label="Select Tags"
                    // options={colourOptions}
                    styles={colourStyles} 
                    isMulti
                    value={selectedOption}
                    onChange={handleChange}
                    options={groupedOptions}

                    />  
                    <img className={`cursor_pointer ${compString1}img1`} src={staticString4} 
                      onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (intStep0 > intStep1 ) {
                              setIntStep1(intStep1 + 1) 
                          }
                          if (intStep0 == intStep1) {
                            //   if (text1 == "" || text2 == "" || text3 == "" || 
                            //       text4 == "" || text5 == "" || text6 == "" || 
                            //       text7 == "" || text8 == ""  
                            //   ) {
                            //       alert("빈 입력값이 있습니다. 입력값을 확인해주세요.")
                            //       return;
                            //   }
                              handleCreate();
                              
                          }
                      }}></img> 
                    {/* <Comp999SelectArea001/> */}
                    </div>
                    </>
                }
                  <img 
                  alt=''
                  className={`cursor_pointer ${compString1}img1`} src={staticString4} 
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (intStep0 > intStep1 ) {
                            setIntStep1(intStep1 + 1) 
                        }
                        if (intStep0 == intStep1) {
                            // if (text1 == "" || text2 == "" || text3 == "" || 
                            //     text4 == "" || text5 == "" || text6 == "" || 
                            //     text7 == "" || text8 == ""  
                            // ) {
                            //     alert("빈 입력값이 있습니다. 입력값을 확인해주세요.")
                            //     return;
                            // }
                            handleSignUp();
                        }
                    }}></img> 

                
            </div>        
            <style>{`
                .${compString1}wrapper {
                    position : fixed;
                    left: 50%;
                    top: 50%;
                    transform : translate(-50%, -50%);
                    z-index: 999;
                    
                    display: flex;
                    flex-direction : column;
                    align-items: center;
                    justify-content: center;


                }
                .${compString1}inner_wrapper {
                    
                    width : calc(100vw - 80px);
                    max-width : 800px;
                    min-height : 500px;
                    
                    display: flex;
                    flex-direction : column;
                    align-items: center;
                    justify-content: center;

                    background-color : #fff;
                    border-radius : 20px;

                
                }
                .${compString1}input01 {

                    width : calc(100% - 40px);
                    height : 85.28px;

                    border-radius : 20px;
                    background :  ${color2};
                    overflow : hidden;
                    display : flex;
                    flex-drection : row; 
                    border : 6px solid ${color1};
    
                    margin-bottom : 20px;
                }
                .${compString1}input01_input_paddingleft {
                    
                    margin-left : 10px;
                    margin-top: auto;
                    margin-right : 4px;
                    margin-bottom : auto;
                    height : calc(100% - 20px);
                    width : 40px;
                    color : ${color3};
                    background-color : ${color3};

                    
                }
                .${compString1}input01_input {
                    
                    margin-left: 20px;
                    width : calc(100% - 40px);
                    min-width : 200px;
                    border: none;
                    outline : none;
                    color : ${color3};
                    font-size: 30px;

                    background : transparent; 
                    
                }
    
                .${compString1}input01_input::placeholder { 
                    color : ${color3};
                    font-weight : 700;
                }
                .${compString1}button0  {

                    width : 200px;
                    height : 50px; 
                    overflow : hidden;
                    display : flex;
                    flex-drection : row; 
                    align-items: center;
                    justify-content: center; 

                    background : transparent;
                    border: none;
                    // border-bottom : 1px solid ${color1};
                    color : ${color1};
                    margin-bottom : 40px;

                }
                .${compString1}img1 {
                    width : 48px;
                    height : 48px;   
                }


                .radius001_image_row_wrapper {
                    width : 100%;
                    overflow-x : scroll;
                    overflow: scroll;
                
                    display: flex;
                    flex-direction: row;
                    /* align-items: center;
                    justify-content: center; */
                
                    align-items: flex-start;
                    justify-content: flex-start;
                
                    max-width: 100vw;
                    overflow: scroll;
                }
                
                .radius001_image_wrapper {
                    width : 100px;
                    height : 100px;
                    min-width : 100px;
                    min-height : 100px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                
                
                    overflow: hidden;
                }
                
                .radius001_image_row_wrapper 
                .radius001_image_wrapper {
                    margin-top: 10px;
                    margin-bottom : 30px;
                    margin-left: 20px;
                }
                
                
                /* width */
                .radius001_image_row_wrapper::-webkit-scrollbar {
                    width: 0px;
                    height: 0px;
                    display: none;
                  }
                  
                  /* Track */
                  .radius001_image_row_wrapper::-webkit-scrollbar-track {
                    background: #f1f1f1; 
                    width: 0px;
                    height: 0px;
                    display: none;
                  }
                  
                  /* Handle */
                  .radius001_image_row_wrapper::-webkit-scrollbar-thumb {
                    background: #181818; 
                    border-radius : 20px;
                    width: 0px;
                    height: 0px;
                    display: none;
                  }
                  
                  /* Handle on hover */
                  .radius001_image_row_wrapper::-webkit-scrollbar-thumb:hover {
                    background: #555; 
                    width: 0px;
                    height: 0px;
                    display: none;
                  }
                  
                  .radius001_0 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  }
                
                
                
                .radius001_image0 {
                    width : 20px;
                    height : 20px;
                    object-fit: contain;
                    
                    margin-left: auto;
                    margin-right : auto;
                }
            `}</style>
        </div>
  );
}

export default Comp;
