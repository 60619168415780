import React, { Component, useEffect, useState } from 'react'; 
import styled from "styled-components"
import Firebase from "firebase";
import firebase from "../firebase";
import fireDb from "../firebase";
import moment from "moment";
 
const color1 = `#8A2BE2`;
const color2 = `#9B1CFF`;
const color3 = `#FFFFFF`;
const compString1 = `popup1_`
const staticString1 = `/static/logo_pictor000.png`
const staticString2 = `/static/icon_sort.png`
const staticString3 = `/static/icon_search.png`

function Comp() {

  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");

    
    function makeId(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
  
    function handleLogin() {

      // var email =  text1
      // var exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

      //   if(exptext.test(email)==false){
      //     toast.error("이메일형식이 올바르지 않습니다.");
      //     return false;
      // }

      console.log("text1 - 0", text1)

      Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo( text1)
            .once("value", (snapshot) => {

                  console.log("text1 - 1", snapshot)

                if (snapshot.val()) {

                  console.log("text1 - 2", snapshot.val())
                    const data = snapshot.val();
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    console.log("text1 - 3", keyName)

                    if (keyName) {

                        console.log("text1 - 4", data[keyName])

                        
                        Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"auto_login" : moment().format("YYYY-MM-DD HH:mm:ss")});
                        // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)

                        if (data[keyName].password == text2) {
                          console.log("text1 - 5",data[keyName].password )
                            //비밀번호와 일치하는지 여부
                            // toast.success("인증되었습니다. ");
                            alert("환영합니다.")
                            // setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}>환영합니다.</div>)
                            // setAuth1({
                            //     username:  data[keyName].username,
                            //     password:  data[keyName].password,
                            //     token:  '',
                            //     more:  data[keyName],
                            // })

                            // console.log("data[keyName]", data[keyName])

                            window.localStorage.setItem("authStoreKeyName",  keyName)
                            window.localStorage.setItem("authStoreKeyName",  keyName);
                            
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                            window.location.href = "/";

                            // window.localStorage.setItem()
                            //지금 수정중
                            // setTimeout(()=> {
                            //   setScreenMode1( 'page001/0/home');
                            // }, 1000 )

                        } else {
                        //   console.log("text1 - 6", text2 )
                            // toast.error("인증실패하였습니다. 비밀번호를 확인해주세요.");
                            // setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>인증실패하였습니다. 비밀번호를 확인해주세요.</div>)
                        }
                    } else {
                        //조건 분기 필요
                            // setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>인증실패하였습니다.</div>)
                    }

                }  else {
                //   setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>인증실패하였습니다.</div>)
                  // 테스트 코드
                  // fireDb.database().ref().child("accounts").push(
                  //   {
                  //     account : text1, 
                  //     email : text1, 
                  //     password : text2,
                  //   }
                  //   , (err) => {
                  //   if (err) {
                  //     toast.error(err);
                  //     console.log(err);
                  //   } else {
                  //     toast.success("Signup  Successfully");
                  //     alert("")
                      
                  //   }
                  // });
                }
            });
    }


  return ( 
      <div className={`${compString1}wrapper`}> 
          <div className={`${compString1}inner_wrapper`}> 
        <div className={`${compString1}input01`}>
            <input className={`${compString1}input01_input`} value={text1} onChange={e=> {setText1(e.target.value)}} placeholder='ID : E-MAIL'></input>
        </div>
        <div className={`${compString1}input01`}>
                  <input className={`${compString1}input01_input`} type={'password'} value={text2} onChange={e=> {setText2(e.target.value)}}  placeholder='PW : '></input>
        </div>
              {/* <div className={`cursor_pointer ${compString1}button0  `}
                  onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                            if (text1 == "" || text2 == ""  
                            ) {
                                alert("빈 입력값이 있습니다. 입력값을 확인해주세요.")
                                return;
                            }
                      handleLogin();
                  }}
              >Log In</div> */}
              <img className="cursor_pointer toplogo001_logo" src="/static/logo_pictor000.png">
              </img>
        </div>        
        <style>{`
            .${compString1}wrapper {
                position : fixed;
                left: 50%;
                top: 50%;
                transform : translate(-50%, -50%);
                z-index: 999;
                 
                display: flex;
                flex-direction : column;
                align-items: center;
                justify-content: center;


            }
            .${compString1}inner_wrapper {
                
                width : calc(100vw - 80px);
                max-width : 800px;
                min-height : 400px;
                
                display: flex;
                flex-direction : column;
                align-items: center;
                justify-content: center;

                background-color : #D9D9D9;
                border-radius : 10px;

            
            }
            .${compString1}input01 {

                width : calc(100% - 40px);
                height : 85.28px;

                border-radius : 20px;
                background :  ${color2};
                overflow : hidden;
                display : flex;
                flex-drection : row; 
                border : 6px solid ${color1};
 
                margin-bottom : 20px;
            }
            .${compString1}input01_input {
                
                margin-left: 20px;
                width : calc(100% - 40px);
                min-width : 200px;
                border: none;
                outline : none;
                color : #fff;
                font-size: 30px;

                background : transparent;

                
            }
 
            .${compString1}input01_input::placeholder { 
                color : #fff; 
                font-weight : 700;
            }
            .${compString1}button0  {

                width : 200px;
                height : 26px; 
                overflow : hidden;
                display : flex;
                flex-drection : row; 
                align-items: center;
                justify-content: center; 

                background : transparent;
                border: none;
                border-bottom : 1px solid #000;

            }


        `}</style>
    </div>
  );
}

export default Comp;
