import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components'; 


const This = styled.div`
  width: 101vw;
  height: 101vh;
  opacity : 0.7;
  background-color : #fff;
  position: fixed;
  z-index: 91;
`;


const Comp = (props) => {
  const { children } = props;

  useEffect(() => {
  }, [])

  return (
    <This 
    id="app_header"
    {...props}
    >
      {children}
    </This>
  );
};

export default Comp;
