import React, { Component, useEffect, useState } from 'react'; 
import styled from "styled-components"
import Firebase from "firebase";
import firebase from "../firebase";
import fireDb from "../firebase";
import moment from "moment";
 
const color1 = `#AE47FF`;
const color2 = `#FEFDFF`;
const color3 = `#FFFFFF`;
const compString1 = `bottomnav1_`
const staticString1 = `/static/logo_pictor000.png`
const staticString2 = `/static/icon_group1.png`
const staticString3 = `/static/icon_logo002.png`

function Comp() {

  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
    const [text3, setText3] = useState("");
    
    function makeId(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
    function handleSubmit() {

                  fireDb.database().ref().child("contacts").push(
                    {
                    //   check_agree1 : boolCheck1,
                    //   check_agree2 : boolCheck2,
                    //   check_agree3 : boolCheck3,
                    //   check_agree4 : boolCheck4,
                    //   nation : textInput1,  //"in" 내국인, "out" 외국인
                      // 지금수정중
                    //   parent_name : textInput2,
                    //   contact_number : textInput3,
                    //   contact_secret : textInput4,
                    //   profile_image : imageLink,
                      username : text1,
                      email : text2, 
                    //   gender : textInput16, // "male", "female"
                    //   usertype : textInput17,
                    //   birthdate : textInput18,
                    //   grade : textInput19, 
                      uuid : moment().format("YYYYMMDDHHmmssSSS"),
                      random_id16 : makeId(16),
                      random_id8 : makeId(8),
                    //   restoration_code : textInput21,

                      // account : textInput1, 
                      // email : textInput1, 
                      // password : textInput2,
                    }
                    , (err) => {
                    if (err) {
                      // toast.error(err);
                      console.log(err);
                    } else {
                        alert("가입되셨습니다. 나머지 정보 기입 및 추가 페이지들은 준비되는대로 공유드리겠습니다.")
                      // toast.success("Signup  Successfully");

                    //   setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}> 회원가입되었습니다.</div>)

                      // setTimeout(()=> {
                      //     const pageCodeGo1 =  "page000/0/login";
                      //     setScreenMode1(pageCodeGo1)
                      // }, 1500)

                      // alert("")
                      
                    }
                  });
    }

  return ( 
      <div className={`${compString1}wrapper`}>
          <div className={`${compString1}row_wrapper1 `}> 
              <div className={`${compString1}col2`}> 
              <img class="cursor_pointer toplogo001_logo" src="/static/logo_pictor000.png"/>
              <br/>
              <div className={`${compString1}col2_menu0`}>Copyright © 2024 ONGROO Inc. <br/>All rights reserved.</div>
               
              </div> 
          </div> 


          <style>{`
            .boldtext001 {
                font-weight: 700;
            }
            .${compString1}wrapper {
                width : 100vw;
                min-height : 2000px;


                background-color : ${color1};

                display: flex;
                flex-drection : row;
                display: flex;
                flex-drection : row;
                margin-top: 100px;
                padding-top: 20px;
                margin-bottom : 0px;
            } 
            .${compString1}row_wrapper1 {
                width : calc(100vw - 100px);
                display: flex;
                flex-wrap: wrap;
                flex-direction : row;

                align-items: flex-start;
                justify-content: flex-start;

                margin-left: auto;
                margin-right  : auto;

            }
            .${compString1}col1 {
                width : 50vw; 
                display: flex;
                flex-direction : column;
                color : #fff;

                margin-left: auto;
                margin-right : auto;

                text-align: left;

            }
            .${compString1}col2 {
                width : 50vw; 
                display: flex;
                flex-direction : column;
                color : #fff;

                margin-left: auto;
                margin-right : auto;

                text-align: left;

            }
            .${compString1}col_full1{
                width : 100vw; 
                display: flex;
                flex-direction : column;
                color : #fff;

                margin-left: auto;
                margin-right : auto;
                
                align-items: center;
                justify-content: center;

            }
            .${compString1}img1 {
                width : 200px;
                height : 100px; 
                object-fit : contain;
                margin-left: auto;
                margin-right  : auto;
                margin-bottom : auto; 
            }
            .${compString1}img2 {
                width : 320px;
                max-width : 50vw;
                height : 320px; 
                object-fit : contain;
                margin-left: auto;
                margin-right  : auto;
                margin-bottom : auto; 

                
            }
            .${compString1}img3 {
                width : 200px;
                height : 100px; 
                object-fit : contain;
                margin-left: auto;
                margin-right  : auto;
                margin-bottom : auto; 
            }
            .${compString1}col2_menu1 { 
                margin-left: auto; 
            }
            .${compString1}col2_menu2 { 
                margin-left: 10px; 
            }
            .${compString1}col2_menu3 { 
                margin-left: 10px; 
            }
            .${compString1}contactinput_label {

                width : 200px;
                height : 26px;
                font-size: 32px;
            }
            .${compString1}contactinput {
                width : 300px;
                height : 26px; 
                overflow : hidden;
                display : flex;
                flex-drection : row;
                margin-top: auto;
                margin-bottom : auto;
                margin-left: 100px;

                background : transparent;
                border: none;
                border-bottom : 1px solid #fff;
 
            }
            .${compString1}contactinput2 {
                width : 636px;
                height : 26px;
                background : transparent;
                border: none; 
                overflow : hidden;
                display : flex;
                flex-drection : row;
                margin-top: auto;
                margin-bottom : auto;
                margin-left: 100px;
 
            }

            .${compString1}contactinput_input {
                margin-left: 20px;
                width : calc(100% - 40px);
                min-width : 200px;
                border: none;
                outline : none;
                color : #fff;
                font-size: 20px;

                background : transparent;
                
            }
            .${compString1}contactinput_input::placeholder { 
                color : #fff; 
                
            }
        `}</style>
    </div>
  );
}

export default Comp;
