import React, { Component, useEffect, useState } from 'react'; 
import styled from "styled-components"
 
const color1 = `#AE47FF`;
const color2 = `#FEFDFF`;
const color3 = `#2DFF0B`;
const color4 = `#FFFFFF`;
const compString1 = `toplogo001_`
const staticString1 = `/static/logo_pictor000.png`
const staticString2 = `/static/icon_sort.png`
const staticString3 = `/static/icon_search.png`

function Comp(props) {

    const [text1, setText1] = useState("");
    const [text2, setText2] = useState("");
    const [textLoggedIn, setTextLoggedIn] = useState("");

    // console.log("props", props)

    useEffect(()=> {

  
        const authStoreKeyName = localStorage.getItem("authStoreKeyName");
        const authStoreUserName = localStorage.getItem("authStoreUserName");
        
        const authStore1 = localStorage.getItem("authStore")
        

        console.log("authStoreUserName", authStoreUserName)
        if (authStoreUserName && authStoreUserName != "") {
            setTextLoggedIn(authStoreUserName)
        }


    }, [])

    console.log("textLoggedIn", textLoggedIn)

  return ( 
      <div className={`${compString1}wrapper`}>
        <img className={`cursor_pointer ${compString1}logo`} src={staticString1}
              onClick={e => {
              e.preventDefault();
                  e.stopPropagation();
                  window.location.href = "/"
              }}
          ></img> 
        {/* <img className={`cursor_pointer layout_dt ${compString1}icon0 ${compString1}icon1`} src={staticString2}
              onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              alert("현재 준비중입니다.")
              }}></img>
        <img className={`cursor_pointer layout_dt ${compString1}icon0 ${compString1}icon2`} src={staticString3}
              onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              alert("현재 준비중입니다.")
              }}></img> */}
        <div className={`${compString1}emptyarea0`}></div>
        <div className={`cursor_pointer ${compString1}button0 ${compString1}button1`} onClick={e => {
              e.preventDefault();
              e.stopPropagation(); 
        }}>About</div>
      {
        textLoggedIn == "" && <>
        <div className={`cursor_pointer ${compString1}button0 ${compString1}button1`} onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              console.log("openPopup1", props.openPopup1)
              props && props.setOpenPopup2 && props.setOpenPopup2(!props.openPopup2);
        }}>Sign up</div>
        <div className={`cursor_pointer ${compString1}button0 ${compString1}buttonLast`} onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            console.log("openPopup1", props.openPopup1)
            props && props.setOpenPopup1 && props.setOpenPopup1(!props.openPopup1);
      }}>Sign in</div>
        </>
      }

{
        textLoggedIn && textLoggedIn != "" && 
        <div className={`cursor_pointer ${compString1}button0 ${compString1}buttonLast`} onClick={e => {
                e.preventDefault();
                e.stopPropagation(); 
                props && props.setOpenPopup3 && props.setOpenPopup3(!props.oenPopup3);
          }}>My page</div>
      }
      {
        textLoggedIn && textLoggedIn != "" && 
        <div className={`cursor_pointer ${compString1}button0 ${compString1}buttonLast`} onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                window.localStorage.clear();
                window.location.href = "/"
          }}>Sign out</div>
      }
        
        {/* <div className={`${compString1}button0 ${compString1}buttonLast`}>Log out</div> */}
        <style>{`
            .${compString1}wrapper {
                width : 100vw;
                height : 44px;
                background-color : ${color1};

                display: flex;
                flex-drection : row;
            }
            .${compString1}logo {
                width : 132px;
                height :44px;
                object-fit : contain;
                margin-left: 20px;
            }
            .${compString1}searchbar01 {
                width : 636px;
                height : 26px;
                border-radius : 20px;
                background :  ${color2};
                overflow : hidden;
                display : flex;
                flex-drection : row;
                margin-top: auto;
                margin-bottom : auto;
                margin-left: 100px;
 
            }

            .${compString1}searchbar01_input {
                margin-left: 20px;
                width : calc(100vw - 40px);
                border: none;
                outline : none;
                color : ${color1};
                font-size: 20px;

                
            }
            .${compString1}icon0 {
                width : 24px;
                height : 24px;
                object-fit : contain;
                margin-top: auto;
                margin-bottom: auto;
            }
            .${compString1}icon1 {
                margin-left: 4px;
            }
            .${compString1}icon2 {
                margin-left: 4px;
                margin-right : auto;
            }
            .${compString1}emptyarea0 {
                margin-left: auto;
                margin-right : auto;
            }

            .${compString1}button0 {
                width : 79px;
                height : 29px;
                min-width : 79px;
                min-height : 29px;
                display: flex;
                flex-drection : row; 
                align-items: center;
                justify-content : center;
                
                background-color : ${color2};
                color : ${color1};
                margin-left: 6px;
                border-radius: 20px;
                margin-top: auto;
                margin-bottom : auto;

            }
            .${compString1}button1 {
                
            }
            .${compString1}button2 {
                
            }
            .${compString1}buttonLast {
                margin-right : 20px;
                
            }


            
        `}</style>
    </div>
  );
}

export default Comp;
