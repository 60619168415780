import React, { Component, useEffect, useState, useRef} from 'react'; 
import styled from "styled-components"
import Firebase from "firebase";
import firebase from "../firebase";
import fireDb from "../firebase";
import moment from 'moment'

import Select from 'react-select'

import QuillWrapper from "react-quill";
import 'react-quill/dist/quill.snow.css'; 
import imageCompression from "browser-image-compression";

const modules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    // ['bold', 'italic', 'underline', 'strike', 'blockquote', 'image', 'camera'],
    // [
    //   { list: 'ordered' },
    //   { list: 'bullet' },
    //   { indent: '-1' },
    //   { indent: '+1' },
    // ],
    // // ['link', 'image', 'video'],
    // // ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  // 'link',
  'image',
  // 'camera',
  // 'video',
]



const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
        outerWidth : "600px",
        innerWidth : "600px",
        
        
      };
    }
  };
  
  
  const categoryOptions1 = [
    
    { value: "디지털그래픽 CGI(2D/3D)	computer-generated-imagery-2d-3d", label: "디지털그래픽 CGI(2D/3D)	computer-generated-imagery-2d-3d", color : "#AE47FF", },
    { value: "웹툰/만화 Webtoon/Comics	webtoon-comics", label: "웹툰/만화 Webtoon/Comics	 ", color : "#AE47FF", },
    { value: "일러스트 Illustration	illustration", label: "일러스트 Illustration	 ", color : "#AE47FF", },
    { value: "캐릭터디자인 Character Design	character-design", label: "캐릭터디자인 Character Design	 ", color : "#AE47FF", },
    { value: "캘리그래피 Calligraphy	calligraphy", label: "캘리그래피 Calligraphy	 ", color : "#AE47FF", },
    { value: "타투디자인 Tatoo Design	tatoo-design", label: "타투디자인 Tatoo Design	 ", color : "#AE47FF", },
    { value: "패션일러스트 Fashion Illustration	fashion-illustration", label: "패션일러스트 Fashion Illustration ", color : "#AE47FF", },
    { value: "패턴디자인 Pattern Design	pattern-design", label: "패턴디자인 Pattern Design ", color : "#AE47FF", },
    { value: "피규어디자인 Art Toy	art-toy", label: "피규어디자인 Art Toy ", color : "#AE47FF", },
    
    
    ];
  
    const categoryOptions2 = [
  
        { value : "사진 Photography	photography", label :  "사진 Photography ", color : "#AE47FF", },
        { value : "조각 Sculpture	sculpture", label :  "조각 Sculpture ", color : "#AE47FF", },
        { value : "판화 Print	print", label :  "판화 Print ", color : "#AE47FF", },
        { value : "회화 Painting	painting", label :  "회화 Painting ", color : "#AE47FF", },
      
      
      ];
  
      
  
      const categoryOptions3 = [
        { value: "모션그래픽 Motion Graphic	motiongraphic", label: "모션그래픽 Motion Graphic", color : "#AE47FF",},
        { value: "미디어아트 Media Art	media-art", label: "미디어아트 Media Art ", color : "#AE47FF",},
        { value: "애니메이션 Animation	animation", label: "애니메이션 Animation ", color : "#AE47FF",},
        { value: "영화 Film Production	Film Production", label: "영화 Film Production ", color : "#AE47FF",},
        
        ]; 
        
  const flavourOptions = [
    { value: "vanilla", label: "Vanilla", rating: "safe" },
    { value: "chocolate", label: "Chocolate", rating: "good" },
    { value: "strawberry", label: "Strawberry", rating: "wild" }
    
  ];
  
  
  
  const groupedOptions = [
    {
      label: "그래픽아트 Graphic Art	Graphic Art",
      options: categoryOptions1
    }, 
    {
      label: "순수미술 Fine Art	fine-art",
      options: categoryOptions2
    }, 
    {
      label: "영상 Video Graphic	videoart",
      options: categoryOptions3
    },  
  ];
 
const color1 = `#8A2BE2`;
const color2 = `#9B1CFF`;
const color3 = `#2DFF0B`;
const compString1 = `popup2_`
const staticString1 = `/static/logo_pictor000.png`
const staticString2 = `/static/icon_sort.png`
const staticString3 = `/static/icon_search.png`
const staticString4 = `/static/icon_wow.png`
const staticString5 = '/static/left-arrow001.png';
const arr1 = ['Account', 'Name', 'beginning', 'habitat', 'Theme?', 'notes', 'Media', 'Artwork']

function Comp(props) {

    const [textUploadId1, setTextUploadId1] = useState(moment().format("YYYYMMDDHHmmssms"))

    const [intStep0, setIntStep0] = useState(4);
    const [intStep1, setIntStep1] = useState(1);
    const [text1, setText1] = useState("");
    const [text2, setText2] = useState("");
    const [text3, setText3] = useState("");
    const [text4, setText4] = useState("");
    const [text5, setText5] = useState("");
    const [text6, setText6] = useState("");
    const [text7, setText7] = useState("");
    const [text8, setText8] = useState("");

    const [selectedOption, setSelectedOption] = useState();
    const [textValue1, setTextValue1] = useState([]);
    const [textValue2, setTextValue2] = useState([]);
  
    const [quillValue1, setQuillValue1] = useState();
  

    const textAreaRef1 = useRef(null);
    const textAreaRef2 = useRef(null);
    const imageRef0 = useRef();
    const imageRef1 = useRef();
    const imageRef2 = useRef();
    const imageRef3 = useRef();
    const imageRef4 = useRef();
    const imageRef5 = useRef();
    const imageRef6 = useRef();
    const imageRef7 = useRef();
    const imageRef8 = useRef();
    const imageRef9 = useRef();
    const imageRef10 = useRef();
  
    const [imageUpload0, setImageUpload0] = useState('');
    const [imageUpload1, setImageUpload1] = useState('');
    const [imageUpload2, setImageUpload2] = useState('');
    const [imageUpload3, setImageUpload3] = useState('');
    const [imageUpload4, setImageUpload4] = useState('');
    const [imageUpload5, setImageUpload5] = useState('');
    const [imageUpload6, setImageUpload6] = useState('');
    const [imageUpload7, setImageUpload7] = useState('');
    const [imageUpload8, setImageUpload8] = useState('');
    const [imageUpload9, setImageUpload9] = useState('');
    const [imageUpload10, setImageUpload10] = useState('');
    
    const [imageLink0, setImageLink0] = useState('');
    const [imageLink1, setImageLink1] = useState('');
    const [imageLink2, setImageLink2] = useState('');
    const [imageLink3, setImageLink3] = useState('');
    const [imageLink4, setImageLink4] = useState('');
    const [imageLink5, setImageLink5] = useState('');
    const [imageLink6, setImageLink6] = useState('');
    const [imageLink7, setImageLink7] = useState('');
    const [imageLink8, setImageLink8] = useState('');
    const [imageLink9, setImageLink9] = useState('');
    const [imageLink10, setImageLink10] = useState('');
    const [intUploadedNumber1, setIntUploadedNumber1] = useState(0);
  
 
  
    const upload = (paramIndex, paramUpload)=>{
   
  
      var returnName1 = `${paramUpload.name}_${moment().format("YYYYMMDD_HHmmss")}`;
      if(paramUpload == null)
        return;
        const uploadTask =  fireDb.storage().ref(`/images/${returnName1}`).put(paramUpload)
        uploadTask
        .on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done');
          // switch (snapshot.state) {
          //   case 'paused':
          //     console.log('Upload is paused');
          //     break;
          //   case 'running':
          //     console.log('Upload is running');
          //     break;
          // }
        }, 
        (error) => {
          // Handle unsuccessful uploads
        }, 
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          fireDb.storage().ref('images').child(returnName1).getDownloadURL()
           .then(fireBaseUrl => {
            //  setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
            console.log("fireBaseUrl", fireBaseUrl) 
  
            if (paramIndex === 0) {
              setImageLink0(fireBaseUrl);
              // 
              Firebase.database().ref()
              .child("behaviors/diary-checkins/" + textUploadId1).update({"avatar" : fireBaseUrl});
            } 
            if (paramIndex === 1) {
              setImageLink1(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
            }
            if (paramIndex === 2) {
              setImageLink2(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
            }
            if (paramIndex === 3) {
              setImageLink3(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
            }
            if (paramIndex === 4) {
              setImageLink4(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
            }
            if (paramIndex === 5) {
              setImageLink5(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
            }
            if (paramIndex === 6) {
              setImageLink6(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
            }
            if (paramIndex === 7) {
              setImageLink7(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
            }
            if (paramIndex === 8) {
              setImageLink8(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
            }
            if (paramIndex === 9) {
              setImageLink9(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
            }
            if (paramIndex === 10) {
              setImageLink10(fireBaseUrl);
              // var tempArr = [];
              // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              //   tempArr = [...objectUserData.photos]
              //   tempArr[paramIndex - 1] = fireBaseUrl
              // } else {
              //   tempArr = [fireBaseUrl]
              // }
              // Firebase.database().ref()
              // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
            }
           })
        }
      );
  
    }

    function makeId(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
  

  const actionImgCompress = async (paramIndex, fileSrc, type) => {
    console.log("압축 시작");

    const options = {
      maxSizeMB: 0.10,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      // 압축 결과
      const compressedFile = await imageCompression(fileSrc, options);
      upload(paramIndex, compressedFile)

    } catch (error) {
      console.log(error);
    }
  };


  useEffect(()=> {
    const index1 = 0;
    actionImgCompress(0, imageUpload0);
    // checkImageNumber();
  },[
    imageUpload0,
  ])

  useEffect(()=> {
    const index1 = 1;
    actionImgCompress(index1, imageUpload1);
    checkImageNumber();
  },[
    imageUpload1,
  ])
  useEffect(()=> {
    const index1 = 2;
    actionImgCompress(index1, imageUpload2);
    checkImageNumber();
  },[
    imageUpload2,
  ])
  useEffect(()=> {
    const index1 = 3;
    actionImgCompress(index1, imageUpload3);
    checkImageNumber();
  },[
    imageUpload3,
  ])

  useEffect(()=> {
    const index1 = 4;
    actionImgCompress(index1, imageUpload4);
    checkImageNumber();
  },[
    imageUpload4,
  ])
  useEffect(()=> {
    const index1 = 5;
    actionImgCompress(index1, imageUpload5);
    checkImageNumber();
  },[
    imageUpload5,
  ])
  useEffect(()=> {
    const index1 = 6;
    actionImgCompress(index1, imageUpload6);
    checkImageNumber();
  },[
    imageUpload6,
  ])
  useEffect(()=> {
    const index1 = 7;
    actionImgCompress(index1, imageUpload7);
    checkImageNumber();
  },[
    imageUpload7,
  ])

  useEffect(()=> {
    const index1 = 8;
    actionImgCompress(index1, imageUpload8);
    checkImageNumber();
  },[
    imageUpload8,
  ])
  useEffect(()=> {
    const index1 = 9;
    actionImgCompress(index1, imageUpload9);
    checkImageNumber();
  },[
    imageUpload9,
  ])
  useEffect(()=> {
    const index1 = 10;
    actionImgCompress(index1, imageUpload10);
    checkImageNumber();
  },[imageUpload10,])

  function checkImageNumber() {
    var checkIndex1 = 0;
    if (imageUpload1) {
      checkIndex1 ++;
    }
    if (imageUpload2) {
      checkIndex1 ++;
    }
    if (imageUpload3) {
      checkIndex1 ++;
    }
    if (imageUpload4) {
      checkIndex1 ++;
    }
    if (imageUpload5) {
      checkIndex1 ++;
    }
    if (imageUpload6) {
      checkIndex1 ++;
    }
    if (imageUpload7) {
      checkIndex1 ++;
    }
    if (imageUpload8) {
      checkIndex1 ++;
    }
    if (imageUpload9) {
      checkIndex1 ++;
    }
    if (imageUpload10) {
      checkIndex1 ++;
    }
    setIntUploadedNumber1(checkIndex1)

  }


  const resizeTextArea = () => {
    if (textAreaRef1 && textAreaRef1.current && textAreaRef1.current.style) {

      textAreaRef1.current.style.height = "auto";
      textAreaRef1.current.style.height = textAreaRef1.current.scrollHeight + "px";
  
    }
    if (textAreaRef2 && textAreaRef2.current && textAreaRef2.current.style) {

      textAreaRef2.current.style.height = "auto";
      textAreaRef2.current.style.height = textAreaRef2.current.scrollHeight + "px";
    }
  };
 

    return ( 
        <div className={`${compString1}wrapper`}> 
            <div className={`${compString1}inner_wrapper`}> 
                
                <div className={`cursor_pointer ${compString1}button0  `}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if ( intStep1 > 0) {
                            setIntStep1(intStep1 - 1)
                        }
                    }}
                >
                    {
                        true &&
                        <img src={staticString5} alt='' style={{ width  : '20px', height : '20px', marginRight : '4px'}}
                        onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation();
                            props && props.onClose && props.onClose();
                        }}
                        />
                    }
 

                   
                </div> 

                { 
                    <>
                    
{/* color 
email 
firstname 
habitat 
id 
index 
lastname 
memo 
name 
nation 
nickname  
random_id8 
random_id16 
tags 
username 
uuid  */}
                    {
                        props.selectedNode0 && props.selectedNode0.username 
                        && props.selectedNode0.username != "" 
                        && 
                        <div className={`${compString1}item0`}>USERNAME : { props.selectedNode0.username}</div>

                    }
                    {
                        props.selectedNode0 && props.selectedNode0.firstname 
                        && props.selectedNode0.firstname != "" 
                        && 
                        <div  className={`${compString1}item0`}>FIRST name : { props.selectedNode0.firstname}</div>

                    } 
                    {
                        props.selectedNode0 && props.selectedNode0.lastname 
                        && props.selectedNode0.lastname != "" 
                        && 
                        <div  className={`${compString1}item0`}>LAST name : { props.selectedNode0.lastname}</div>

                    } 
                    {
                        props.selectedNode0 && props.selectedNode0.habitat 
                        && props.selectedNode0.habitat != "" 
                        && 
                        <div  className={`${compString1}item0`}>habitat : { props.selectedNode0.habitat}</div>

                    } 
                    {
                        props.selectedNode0 && props.selectedNode0.nation 
                        && props.selectedNode0.nation != "" 
                        && 
                        <div  className={`${compString1}item0`}>nation : { props.selectedNode0.nation}</div>

                    } 
                    {
                        props.selectedNode0 && props.selectedNode0.memo 
                        && props.selectedNode0.memo != "" 
                        && 
                        <div  className={`${compString1}item0`}>memo : { props.selectedNode0.memo}</div>

                    } 
                    {
                        props.selectedNode0 && props.selectedNode0.tags 
                        && props.selectedNode0.tags != "" 
                        && 
                        <div  className={`${compString1}item0`}>memo : { props.selectedNode0.tags}</div>

                    } 


                    {
                        props && props.selectedNodes1 && Array.from(props.selectedNodes1).map((mapItem, mapIndex)=> {
                            // console.log("mapItem", mapItem)
                            return (
                                <div className={`${compString1}workItem0`}>
                                {
                                    mapItem && mapItem.title &&mapItem.title &&
                                    <div className={`${compString1}text1`}>{mapItem.title}</div>
                                }
                                {
                                    mapItem && mapItem.imageLink1 
                                    && mapItem.imageLink1 != "" 
                                    && <img  className='workItemImg' alt='' src={mapItem.imageLink1}/>
            
                                }
                                {
                                    mapItem && mapItem.imageLink2
                                    && mapItem.imageLink2 != "" 
                                    && <img  className='workItemImg' alt='' src={mapItem.imageLink2}/>
            
                                }
                                {
                                    mapItem && mapItem.imageLink3
                                    && mapItem.imageLink3 != "" 
                                    && <img  className='workItemImg' alt='' src={mapItem.imageLink3}/>
            
                                }
                                {
                                    mapItem && mapItem.imageLink4
                                    && mapItem.imageLink4 != "" 
                                    && <img  className='workItemImg' alt='' src={mapItem.imageLink4}/>
            
                                }
                                {
                                    mapItem && mapItem.imageLink5
                                    && mapItem.imageLink5 != "" 
                                    && <img  className='workItemImg' alt='' src={mapItem.imageLink5}/>
            
                                }
                                {
                                    mapItem && mapItem.imageLink6
                                    && mapItem.imageLink6 != "" 
                                    && <img  className='workItemImg' alt='' src={mapItem.imageLink6}/>
            
                                }
                                {
                                    mapItem && mapItem.imageLink7
                                    && mapItem.imageLink7 != "" 
                                    && <img  className='workItemImg' alt='' src={mapItem.imageLink7}/>
            
                                }
                                {
                                    mapItem && mapItem.imageLink8
                                    && mapItem.imageLink8 != "" 
                                    && <img  className='workItemImg' alt='' src={mapItem.imageLink8}/>
            
                                }
                                {
                                    mapItem && mapItem.imageLink9
                                    && mapItem.imageLink9 != "" 
                                    && <img className='workItemImg' alt='' src={mapItem.imageLink9}/>
            
                                }
                                {
                                    mapItem && mapItem.imageLink10
                                    && mapItem.imageLink10 != "" 
                                    && <img  className='workItemImg' alt='' src={mapItem.imageLink10}/>
            
                                }
                                </div>
                            )
                        })
                    }
                        
                    </>
                    
                }
                  <img 
                  alt=''
                  className={`cursor_pointer ${compString1}img1`} src={staticString4} 
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation(); 
                        props && props.onClose && props.onClose();
                    }}></img> 

                
            </div>        
            <style>{`
                .${compString1}wrapper {
                    position : fixed;
                    left: 50%;
                    top: 50%;
                    transform : translate(-50%, -50%);
                    z-index: 999;
                    
                    display: flex;
                    flex-direction : column;
                    align-items: center;
                    justify-content: center;



                    
                }
                .${compString1}inner_wrapper {
                    
                    width : calc(100vw - 80px);
                    max-width : 800px;
                    min-height : 400px;
                    
                    display: flex;
                    flex-direction : column;
                    align-items: center;
                    justify-content: center;

                    background-color : #fff;
                    border-radius : 20px;

                    overflow : scroll;
                }
                .${compString1}input01 {

                    width : calc(100% - 40px);
                    height : 85.28px;

                    border-radius : 20px;
                    background :  ${color2};
                    overflow : hidden;
                    display : flex;
                    flex-drection : row; 
                    border : 6px solid ${color1};
    
                    margin-bottom : 20px;
                }
                .${compString1}input01_input_paddingleft {
                    
                    margin-left : 10px;
                    margin-top: auto;
                    margin-right : 4px;
                    margin-bottom : auto;
                    height : calc(100% - 20px);
                    width : 40px;
                    color : ${color3};
                    background-color : ${color3};

                    
                }
                .${compString1}input01_input {
                    
                    margin-left: 20px;
                    width : calc(100% - 40px);
                    min-width : 200px;
                    border: none;
                    outline : none;
                    color : ${color3};
                    font-size: 30px;

                    background : transparent; 
                    
                }
    
                .${compString1}input01_input::placeholder { 
                    color : ${color3};
                    font-weight : 700;
                }
                .${compString1}button0  {

                    width : 200px;
                    height : 50px; 
                    overflow : hidden;
                    display : flex;
                    flex-drection : row; 
                    align-items: center;
                    justify-content: center; 

                    background : transparent;
                    border: none;
                    // border-bottom : 1px solid ${color1};
                    color : ${color1};
                    margin-bottom : 40px;

                }
                .${compString1}img1 {
                    width : 48px;
                    height : 48px;   
                    margin-bottom : 20px;
                    margin-top: 20px;
                }


                .radius001_image_row_wrapper {
                    width : 100%;
                    overflow-x : scroll;
                    overflow: scroll;
                
                    display: flex;
                    flex-direction: row;
                    /* align-items: center;
                    justify-content: center; */
                
                    align-items: flex-start;
                    justify-content: flex-start;
                
                    max-width: 100vw;
                    overflow: scroll;
                }
                
                .radius001_image_wrapper {
                    width : 100px;
                    height : 100px;
                    min-width : 100px;
                    min-height : 100px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                
                
                    overflow: hidden;
                }
                
                .radius001_image_row_wrapper 
                .radius001_image_wrapper {
                    margin-top: 10px;
                    margin-bottom : 30px;
                    margin-left: 20px;
                }
                
                
                /* width */
                .radius001_image_row_wrapper::-webkit-scrollbar {
                    width: 0px;
                    height: 0px;
                    display: none;
                  }
                  
                  /* Track */
                  .radius001_image_row_wrapper::-webkit-scrollbar-track {
                    background: #f1f1f1; 
                    width: 0px;
                    height: 0px;
                    display: none;
                  }
                  
                  /* Handle */
                  .radius001_image_row_wrapper::-webkit-scrollbar-thumb {
                    background: #181818; 
                    border-radius : 20px;
                    width: 0px;
                    height: 0px;
                    display: none;
                  }
                  
                  /* Handle on hover */
                  .radius001_image_row_wrapper::-webkit-scrollbar-thumb:hover {
                    background: #555; 
                    width: 0px;
                    height: 0px;
                    display: none;
                  }
                  
                  .radius001_0 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  }
                
                
                
                .radius001_image0 {
                    width : 20px;
                    height : 20px;
                    object-fit: contain;
                    
                    margin-left: auto;
                    margin-right : auto;
                }
                
                .workItemImg {
                    width : 100%;
                    max-height: 300px;
                    object-fit : contain;

                    margin-bottom : 40px;
                }
                .${compString1}item0 {
                    width : 300px;
                    max-width : 300px;

                    display: flex;

                    color : ${color1};
                    margin-top: 4px;
                    margin-bottom: 4px;

                    font-size: 20px;

                }
                .${compString1}workItem0 {
                    
                    width : 100%;
                    max-height: 300px;
                    margin-bottom : 20px;
                }
                .${compString1}workItem0  > .${compString1}text1 {

                    width : 300px;
                    max-width : 300px;

                    display: flex;
                    font-size: 18px;
                    align-items: center;
                    justify-content: center;

                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom : 8px;

                }
            `}</style>
        </div>
  );
}

export default Comp;
