import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components'; 

const This = styled.div`
  width: 101vw;
  height: 101vh;
  opacity : 0.3;
  background-color : #000;
  position: fixed;
  z-index: 91;
  top: 0;
  left:0;

  display: flex;
  align-items: center;
  justify-content: center;
`;


const Comp = (props) => {
  const { children } = props;

  useEffect(() => {
  }, [])

  return (
    <This 
    id="app_header"
    {...props}
    >
      {children}
    </This>
  );
};

export default Comp;
